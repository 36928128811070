/* #slide {
    position: absolute;

    bottom: -1000px;

    left:25%;

    right:25%;

   
    -webkit-animation: slide 0.5s forwards;

    -webkit-animation-delay: 2s;

    animation: slide 0.5s forwards;


}


@-webkit-keyframes slide {
    100% { bottom: 0;
 }

}


@keyframes slide {
    100% { bottom: 0;
 }

}
 */

/* @media all and (max-width:767px){body .tto-root{font-size:14px;
font-weight:400}
}
.legacy h1,#dialogRegion.legacy span.title,#dialogRegion.section_file .FnFtitle,#modalContainer .FnFtitle{font-weight:400;
font-size:30px;
color:#0077c5;
text-align:left;
position:relative}
@media all and (max-width:767px){.legacy h1,#dialogRegion.legacy span.title,#dialogRegion.section_file .FnFtitle,#modalContainer .FnFtitle{font-weight:400;
color:#6b6c72;
font-size:24px;
text-align:center}
}
.legacy h2{font-weight:400;
font-size:24px;
margin:0}
.legacy h3,.legacy #sectionTitle{font-weight:600;
font-size:20px;
margin:0;
text-align:left;
text-decoration:none}
@media all and (max-width:767px){.legacy h3,.legacy #sectionTitle{font-size:18px;
font-weight:700}
}
@media all and (max-width:767px){.legacy #sectionTitle{display:none}
}
.legacy h3 span{font-size:16px;
font-weight:400}
.legacy h4{font-weight:100}
.legacy strong,.legacy .bold,.legacy b{font-weight:700}
.legacy em,.legacy .italic{font-style:italic}
.legacy ul{list-style-type:disc;
padding-left:20px}
.legacy ul li{padding-top:8px}
.legacy p,.legacy li,.legacy table.grid td,.legacy table.gridV2 td{font-size:16px;
line-height:26px}
@media all and (max-width:767px){.legacy p,.legacy li,.legacy table.grid td,.legacy table.gridV2 td{font-size:14px}
}
.legacy p{margin:16px 0}
.legacy .screenid_prepare_state_home .deleteLink{padding-left:10px}
.legacy a{cursor:pointer;
text-decoration:none}
.legacy a.none,.legacy a.textLnk{text-decoration:underline}
@media all and (max-width:767px){.legacy a.none,.legacy a.textLnk{text-decoration:none}
}
.legacy input,.legacy textarea,.legacy select,.legacy select>option,.legacy button{font-weight:100}
@media all and (max-width:767px){.legacy input,.legacy textarea,.legacy select,.legacy select>option,.legacy button{font-weight:400}
}
.font_0{font-weight:700;
color:#393a3d;
background:#FF9}
.font_1{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d;
background:#FF9}
.font_2{font-weight:700;
font-size:1em;
color:#393a3d;
background:#CFC}
.font_3{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d;
background:#CFC}
.font_4{font-weight:700;
font-size:.9230769230769231em;
color:#FFF;
background:#8d9096}
.font_5{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d;
background:#09F}
.font_6{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d;
background:#09F}
.title{font-weight:700;
font-size:1.3846153846153846em;
color:#333}
.titleitalic{font-weight:700;
font-size:1.3846153846153846em;
color:#333}
.subtitle{font-weight:600;
font-size:1.0769230769230769em}
.question1{font-weight:700;
font-size:1.0769230769230769em;
color:#393a3d}
.bolditalic{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d}
.screenid_prepare_state_home .maintext{padding-right:10px;
vertical-align:text-top}
@media all and (max-width:767px){.screenid_prepare_state_home .maintext{padding-right:0;
vertical-align:text-top;
font-size:12px}
}
.screenid_prepare_state_home .mainBoldText{padding-right:10px;
vertical-align:text-top;
font-weight:700}
@media all and (max-width:767px){.screenid_prepare_state_home .mainBoldText{padding-right:0;
vertical-align:text-top;
font-weight:700;
font-size:12px}
}
.main{color:#393a3d}
.mainbold{font-weight:700;
color:#393a3d}
.mainitalic{font-style:italic;
color:#393a3d}
.mainbolditalic{font-weight:700;
font-style:italic;
color:#393a3d}
.caution{font-weight:700;
font-size:.9230769230769231em;
color:red}
.baldue{font-weight:700;
font-size:.9230769230769231em;
color:#888}
.survey1{font-weight:700;
color:#336}
.survey2{font-size:.9230769230769231em;
color:#666}
.username{font-weight:700;
font-size:.9230769230769231em;
color:#692B12}
.goodthing{font-weight:700;
font-size:.9230769230769231em;
color:#2cbc9b}
.important{font-weight:700;
color:#CE0000}
.example{font-weight:700;
font-size:.9230769230769231em;
color:#006}
.note{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d}
@media all and (max-width:767px){.note{font-style:italic}
}
.anchor{font-weight:700;
font-size:1.8461538461538463em;
color:#336}
.title24{font-weight:700;
font-size:1.8461538461538463em;
color:#36C}
.title20{font-weight:700;
font-size:1.5384615384615385em;
color:#36C}
.title20black{font-weight:700;
font-size:1.5384615384615385em;
color:#393a3d}
.title20gray{font-weight:700;
font-size:1.5384615384615385em;
color:#333}
.title18{font-weight:700;
font-size:1.3846153846153846em;
color:#336}
.title18black{font-weight:700;
font-size:1.3846153846153846em;
color:#393a3d}
.subtitle18{font-weight:700;
font-size:1.3846153846153846em;
color:#336}
.subtitle16{font-weight:700;
font-size:1.2307692307692308em;
color:#336}
.subtitle16blue{font-weight:700;
font-size:1.2307692307692308em;
color:#06F}
.subtitle14{font-weight:700;
font-size:1.0769230769230769em;
color:#336}
.body{color:#393a3d}
.body16{font-size:1.2307692307692308em;
color:#333}
.body14{font-size:1.0769230769230769em;
color:#333}
.body13{color:#393a3d}
.body11{font-size:.9230769230769231em;
color:#393a3d}
.body11gray{font-size:.9230769230769231em;
color:#8d9096}
.prioryear{font-size:.9230769230769231em;
color:#8d9096}
.question{font-weight:700;
font-size:1.0769230769230769em;
color:#393a3d}
.guideme{font-weight:700;
font-size:1.5384615384615385em;
color:#FFF;
background:#6C6}
.notetext{font-size:.9230769230769231em;
color:#393a3d}
.key{font-weight:700;
color:#393a3d}
.alert{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d;
background:#FF9}
#txtblk_00_0.alert{background:0 0;
color:red;
padding:10px 0 10px 0}
.alerttext{font-size:.9230769230769231em;
color:#393a3d}
.colh{font-weight:700;
font-size:.9230769230769231em;
color:#C60}
.mainorange{font-size:1em;
color:#C60}
.gridtitle{font-weight:700;
font-size:1.2307692307692308em;
color:#393a3d}
.subtotal{font-weight:700;
font-size:1.2307692307692308em;
color:#393a3d}
.tableh{font-weight:700;
font-size:.9230769230769231em;
color:#FFF}
.smallkey{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d}
.fineprint{font-size:.7692307692307693em;
color:#666}
.formname{font-size:.9230769230769231em;
color:#666}
.status{font-size:.9230769230769231em;
color:#8d9096}
.hthcolh{font-weight:700;
font-size:.9230769230769231em;
color:#333}
.refund{font-weight:700;
font-size:1.0769230769230769em;
color:#36C}
.lndtblheadtitle{font-weight:700;
font-size:1em;
color:#393a3d;
display:inline-block;
margin:10px 10px 0 0}
.lndtblheaddesc{font-size:1em;
color:#505050}
.lndtblsubheadtitle{font-weight:700;
font-size:1em;
color:#393a3d}
.lndtblsubheaddesc{font-size:1em;
color:#393a3d}
.lndtblnote{font-size:.9230769230769231em;
color:#8d9096}
.lndtblcolhead{font-weight:700;
font-size:.9230769230769231em;
color:#505050}
.lndtblcolstatus{font-size:.9230769230769231em;
color:#606060}
.legacy a.lndtblcolstatus{text-decoration:underline}
.lndtblmissinginfo{font-size:.9230769230769231em;
color:#2cbc9b}
.bigmoneygreen{font-size:1.3846153846153846em;
color:#2cbc9b}
.bigmoneyblack{font-size:1.3846153846153846em;
color:#333}
.bigmoneyred{font-size:1.3846153846153846em;
color:#E31B23}
.bigmoneygray{font-size:1.3846153846153846em;
color:#666}
.boldmaindarkblue{font-weight:700;
font-size:1em;
color:#1E2C5D}
.bodygreenstrong{font-weight:700;
font-size:1em;
color:#2cbc9b}
.bodygreen{font-size:1em;
color:#2cbc9b}
.guidemebtnsmall{font-size:.9230769230769231em;
color:#1763AF}
.guidemebtnlarge{font-size:1.2307692307692308em;
color:#1763AF}
.strikeout{text-decoration:line-through}
.lndtbltabs{font-weight:700;
font-size:.9230769230769231em;
color:#FFF}
.tablndingsubhead{font-weight:700;
font-size:1.2307692307692308em;
color:#FFF}
.tablndingtext{font-size:1em;
color:#FFF}
.tablndingtextbold{font-weight:700;
font-size:1em;
color:#FFF}
.tablndinghead{font-weight:700;
font-size:1.8461538461538463em;
color:#004102}
.tablndinglink{font-size:.9230769230769231em;
color:#C7C8C9}
.trmmachead{font-weight:700;
font-size:1.2307692307692308em;
color:#6D6D6D}
.trmmacsubsection{font-weight:700;
font-size:1.0769230769230769em;
color:#6D6D6D}
.trmmacsub{font-size:1em;
color:#6A6A6A}
.credential{font-size:1em;
color:#8d9096}
.statuspending{font-weight:700;
font-size:1.0769230769230769em;
color:#06C}
.statusaccepted{font-weight:700;
font-size:1.0769230769230769em;
color:#390}
.statusrejected{font-weight:700;
font-size:1.0769230769230769em;
color:#C00}
.statusvisited{font-weight:700;
font-size:1.0769230769230769em;
color:#C60}
.yourreturn{font-weight:700;
font-size:1.0769230769230769em;
color:#666}
._leftnavbkgd{font-weight:700;
font-size:1.8461538461538463em;
color:#F5FCFF}
._hypertext{font-size:1em;
color:#06F}
._buttonctrl{font-weight:700;
font-size:1.0769230769230769em;
color:#06F}
._smallbuttonctrl{font-weight:700;
font-size:1.0769230769230769em;
color:#06F}
._refundmonitor{font-weight:700;
font-size:1.0769230769230769em;
color:#F5FCFF}
.lightGrey{color:#8d9096}
.lightGreyStrong{color:#8d9096;
font-weight:700}
.largeLightGrey{color:#8d9096;
font-size:38px}
.lightGreen{color:#2cbc9b}
.lightGreenStrong{color:#2cbc9b;
font-weight:700}
.largeLightGreen{color:#2cbc9b;
font-size:38px}
.smallLabel{font-size:13px}
.smallTitle{font-size:24px;
color:#0077c5}
.statelandingtablebox{background-color:#E6FAFD;
padding:36px 24px;
position:relative;
border:rgba(61,185,241,.23) 1px solid;
border-radius:0;
background:-moz-linear-gradient(top,#fff 0,#eefaff 100%);
background:-webkit-gradient(left top,left bottom,color-stop(0,#fff),color-stop(100%,#eefaff));
background:-webkit-linear-gradient(top,#fff 0,#eefaff 100%);
background:-o-linear-gradient(top,#fff 0,#eefaff 100%);
background:-ms-linear-gradient(top,#fff 0,#eefaff 100%);
background:linear-gradient(to bottom,#fff 0,#eefaff 100%)}
.statelandingtablebox>*{z-index:3;
position:relative}
.statelandingtablebox:after{content:' ';
background-image:url();
background-repeat:repeat;
width:100%;
height:100%;
top:0;
left:0;
position:absolute;
z-index:0}
.white{position:relative;
padding:5px;
border:solid 1px #ccc;
border-radius:8px;
-moz-border-radius:8px;
-webkit-border-radius:8px;
background-color:#FFF}
.gray{position:relative;
padding:30px;
border:solid 1px #E0DEDE;
border-radius:8px;
-moz-border-radius:8px;
-webkit-border-radius:8px;
background-color:#F8F8F8;
background-image:-moz-linear-gradient(center top,#ede9e9 0,#f8f8f8 100%);
background-image:-webkit-gradient(linear,center top,center bottom,from(#ede9e9),to(#f8f8f8));
-pie-background:linear-gradient(#ede9e9,#f8f8f8)}
.blockgray{position:relative;
padding:5px;
border:solid 1px #EEE;
border-radius:8px;
-moz-border-radius:8px;
-webkit-border-radius:8px;
background-color:#F4F4F4}
.blockyellow{position:relative;
padding:5px;
border:solid 1px #EEE;
border-radius:8px;
-moz-border-radius:8px;
-webkit-border-radius:8px;
background-color:#FFC}
.solid_gray{position:relative;
padding:5px;
border:solid 1px #ccc;
border-left:0;
border-right:0;
background-color:#F4F4F4}
.gridtotals{position:relative;
padding:10px;
border:solid 1px #ccc;
background-color:#F8F8FF}
.landingtableheader{position:relative;
padding:0;
border:solid 2px #A9A9A9;
border-top:0;
border-left:0;
border-right:0}
.landingtablepycolheader{position:relative;
padding:0;
border-radius:5px 5px 0 0;
-moz-border-radius:5px 5px 0 0;
-webkit-border-radius:5px 5px 0 0;
background-color:#AEB1B9;
color:#FFF}
.landingtablecycolheader{position:relative;
padding:0;
border-radius:5px 5px 0 0;
-moz-border-radius:5px 5px 0 0;
-webkit-border-radius:5px 5px 0 0;
background-color:#7F8188;
color:#FFF}
.blue.gradient{position:relative;
padding:0;
border:solid 1px #7A8995;
border-radius:8px;
-moz-border-radius:8px;
-webkit-border-radius:8px;
background-color:#DBEDFC;
background-image:-moz-linear-gradient(center top,#fff 0,#c0dff8 3%,#fff 6%,#dbedfc 10%,#bdf0fd 50%,#dbedfc 72%,#fff 93%,#bddef9 97%,#fff 100%);
background-image:-webkit-gradient(linear,center top,center bottom,from(#fff),color-stop(.03,#c0dff8),color-stop(.06,#fff),color-stop(.1,#dbedfc),color-stop(.5,#bdf0fd),color-stop(.72,#dbedfc),color-stop(.93,#fff),color-stop(.97,#bddef9),to(#fff));
-pie-background:linear-gradient(#fff,#fff)}
.requiredfield{position:relative;
padding:1px;
background-color:#EB332D}
.nonrequiredfield{position:relative;
padding:1px;
background-color:#FFF}
.rule3solid{font-size:0;
border-top:solid 3px #ccc;
width:100%}
.rule2solid{font-size:0;
border-top:solid 2px #ccc;
width:100%}
.rule1solid{font-size:0;
border-top:solid 1px #ccc;
width:100%}
.rule1dashes{font-size:0;
border-top:dashed 1px #ccc;
width:100%}
.bulletPoint:before{content:"â€¢ "}

  @media all and (min-width:768px) and (max-width:991px){.legacy .container,#ttoContainer>#main.container{max-width:none}
}
body,h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{font-family:"Avenir Next forINTUIT",Avenir,Helvetica,Arial,sans-serif}
a,.btn-link{color:#037c8f}
.legacy a:not(.btn),.legacy .btn-link{font-weight:500;
color:#037c8f}
.legacy a:not(.btn):hover,.legacy .btn-link:hover{color:#0493a3}
.legacy a:not(.btn):focus,.legacy .btn-link:focus{color:#0493a3}
.legacy a:not(.btn):active,.legacy .btn-link:active{color:#05a4b5}
.legacy a:not(.btn):hover,.legacy .btn-link:hover,.legacy a:not(.btn):active,.legacy .btn-link:active,.legacy a:not(.btn):focus,.legacy .btn-link:focus{text-decoration:underline}
.legacy .btn{-webkit-border-radius:4px;
-webkit-background-clip:padding-box;
-moz-border-radius:4px;
-moz-background-clip:padding;
border-radius:4px;
background-clip:padding-box;
padding:10px 20px;
font-weight:500;
-webkit-transition:all 100ms;
-moz-transition:all 100ms;
-ms-transition:all 100ms;
-o-transition:all 100ms;
transition:all 100ms;
font-size:14px}
@media all and (max-width:767px){.legacy .btn{-webkit-border-radius:2px;
-webkit-background-clip:padding-box;
-moz-border-radius:2px;
-moz-background-clip:padding;
border-radius:2px;
background-clip:padding-box;
white-space:normal;
padding:10px}
}
.legacy .btn:not(.btn-default){border-color:transparent;
background-clip:inherit}
.legacy .btn:focus{text-decoration:none;
outline:0}
.legacy .btn:hover,.legacy .btn:active{text-decoration:none}
.legacy .btn:hover:not(.btn-default),.legacy .btn:active:not(.btn-default){border-color:transparent}
.legacy .btn:hover,.legacy .btn:focus{background-position:0 0}
.legacy .btn.btn-primary{text-shadow:none;
background:#037c8f}
.legacy .btn.btn-primary:hover{background:#05bdda}
.legacy .btn.btn-primary:active{background:#01252b}
.legacy .btn-group.open .dropdown-toggle.btn-primary{border-color:transparent;
background:#01252b}
.legacy .navbar{background-image:none}
.legacy #directDebitPayDate{font-size:1.25em}
@-webkit-keyframes pulsate{from{-webkit-transform:scale(1);
opacity:1}
50%{-webkit-transform:scale(1.2);
opacity:1}
to{-webkit-transform:scale(1);
opacity:1}
}
.legacy .pulsate{opacity:1;
-webkit-animation-name:pulsate;
-webkit-animation-duration:2s;
-webkit-animation-iteration-count:infinite}
.legacy .slider{margin-top:6px}
.legacy .slider.slider-horizontal .slider-track{height:15px;
border:2px solid #aaa;
background-color:#fff;
background-image:none}
.legacy .slider.slider-horizontal .slider-track .slider-selection{background-color:#00b800;
background-image:none;
border-radius:0!important;
box-shadow:none;
-webkit-box-shadow:none}
.legacy .slider.slider-horizontal .slider-track .slider-handle{background-color:#e6e6e6;
background-image:none;
border:2px solid #999;
border-radius:7px!important;
opacity:1;
height:22px;
width:22px}
.legacy .close{font-weight:700}
.legacy .close:hover{zoom:1;
filter:alpha(opacity=100);
-webkit-opacity:1;
-moz-opacity:1;
opacity:1;
color:#33b8f3}
.legacy .popover{-webkit-box-shadow:0 0 30px rgba(0,101,189,.5);
-moz-box-shadow:0 0 30px rgba(0,101,189,.5);
box-shadow:0 0 30px rgba(0,101,189,.5);
border:1px solid #ccc}
.legacy .popover.right .arrow{border-right-color:#ccc}
.legacy .popover.bottom .arrow{border-bottom-color:#ccc}
.legacy .popover.top .arrow{border-top-color:#ccc}
.legacy .popover.left .arrow{border-left-color:#ccc}
.legacy nav .popover-content{padding:0}
.legacy nav .popover-content #refund_explain .date{padding:2px 10px;
background-color:#eee;
font-size:12px}
.legacy nav .popover-content #refund_explain table>tbody>tr>td:first-child{font-size:20px}
.legacy nav .popover-content #refund_explain table>tbody>tr>td:nth-child(2){font-size:12px}
.legacy nav .popover-content #refund_explain table>tbody>tr>td:last-child{font-size:24px}
.legacy .table-hover>tbody>tr:hover>td,.legacy .table-hover>tbody>tr:hover>th{background-color:#DEEDF4;
cursor:pointer}
.legacy ul.nav-list li a{border-bottom:1px solid #ccc;
border-right:1px solid #ccc}
.legacy ul.nav-list li:first-child a{border-top:1px solid #ccc}
.legacy .nav>li>a:hover,.legacy .nav>li>a:focus{background-color:rgba(255,255,255,.1);
color:#fff}
.legacy #validation_alert.modal .modal-body{overflow:hidden}
.legacy #validation_alert.modal .modal-body p{display:inline-block}
.legacy #validation_alert.modal .modal-body button{float:right}
.legacy .modal-header img{float:left;
margin-right:10px}
.legacy .modal-backdrop.in{height:1000%}
.modal .modal-backdrop{z-index:auto}
.normal_font_label{display:inline;
font-weight:400}
#dialogRegion #contentRegion #contentLayer table .inputBtnLbl label{display:inline;
font-weight:400}
#dialogRegion.section_file.legacy label,#modalContainer label{display:inline;
font-weight:400}

  .loading-screen-hidden{display:none}
@media all and (max-width:991px){.native-app #main{margin-top:15px}
}
@media all and (min-width:992px){.native-app #main #main_right.subnav_fixed #dialogRegion{padding-top:84px}
}
@media all and (max-width:767px){.native-app #main #main_right #dialogRegion{padding:25px 15px;
min-height:0}
.native-app #main #main_right #dialogRegion .L-btn{float:none;
width:20%}
}
@media all and (max-width:480px){.native-app #main #main_right #dialogRegion .L-btn{width:30%;
margin-bottom:-20px;
margin-top:-5px}
}

   */
#scroll_wrapper #contentLayer{min-height:30vh;
    padding-bottom:0}




#main #main_left,#main #main_right{position:relative}
#main #main_left{z-index:3}
#main #main_left #tabRegion{display:none}
#main #main_left #partnerLogoLayer.partner-visible{margin-bottom:10px;
    text-align:right}
#main #main_left #partnerLogoLayer.partner-visible .presentedBy{color:#fff;
    font-size:12px}
@media all and (min-width:991px){#main #main_left #partnerLogoLayer.partner-visible .presentedBy{display:none}
}
@media all and (max-width:767px){#main #main_left #partnerLogoLayer.partner-visible .presentedBy{display:inline}
}
#main #main_left #partnerLogoLayer.partner-visible img{margin-left:10px}
@media all and (min-width:767px){#main #main_left #partnerLogoLayer.partner-visible{text-align:right}
}
@media all and (max-width:991px){#main #main_left #partnerLogoLayer.partner-visible{text-align:left}
}
#main #main_right #dialogRegion{position:relative;
    padding:40px 0 0;
    min-height:410px}
@media all and (max-width:767px){#main #main_right #dialogRegion{padding-top:10px}
}
@media all and (min-width:768px) and (max-width:991px){#main #main_right #dialogRegion{padding-top:20px}
}
#main #main_right #dialogRegion #contentRegion{position:relative;
    width:100%}
#scroll_wrapper{position:relative}
#scroll_wrapper #scroll_fade{display:none}
#scroll_wrapper #contentLayer{position:relative;
    margin:0;
    padding:0;
    min-height:340px}
#scroll_wrapper #contentLayer .container-fluid{padding-left:0;
    padding-right:0}
#scroll_wrapper #contentLayer h1:first-child{margin-top:0}
#scroll_wrapper #formLayer{display:none;
    width:100%!important;
    -webkit-box-shadow:0 0 5px #666;
    -moz-box-shadow:0 0 5px #666;
    box-shadow:0 0 5px #666;
    position:relative;
    overflow:auto;
    margin:15px auto}
#scroll_wrapper.on #scroll_fade{display:block;
    position:absolute;
    right:0;
    width:5%;
    height:95%;
    z-index:1;
    background:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background:-moz-linear-gradient(left,rgba(255,255,255,0) 0,#fff 100%);
    background:-webkit-gradient(linear,left top,right top,color-stop(0,rgba(255,255,255,0)),color-stop(100%,#fff));
    background:-webkit-linear-gradient(left,rgba(255,255,255,0) 0,#fff 100%);
    background:-o-linear-gradient(left,rgba(255,255,255,0) 0,#fff 100%);
    background:-ms-linear-gradient(left,rgba(255,255,255,0) 0,#fff 100%);
    background:linear-gradient(to right,rgba(255,255,255,0) 0,#fff 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1)}
#scroll_wrapper.on #formLayer{width:100%;
    overflow-x:auto;
    }
#scroll_wrapper.on #formLayer .borderedFormLayer{background-color:#FFF;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
#main #main_right #dialogRegion #contentLayer.formExists{min-height:100px!important}
#main #main_right #dialogRegion #titleGraphicLayer{position:relative;
    margin-left:20px;
    padding-bottom:5px}
#main #main_right #dialogRegion #titleLayer{position:relative;
    padding:0 0 20px}
@media all and (max-width:767px){#main #main_right #dialogRegion #titleLayer{padding-bottom:15px;
    text-align:center}
}
#main #main_right #dialogRegion #titleLayer.sessionless{margin-left:0}
#main #main_right #dialogRegion #subtitleLayer{position:relative;
    margin-left:20px;
    padding-bottom:5px}
#main #main_right #dialogRegion #iconLayer{position:relative;
    margin-left:20px;
    font-size:0}
#main #main_right #dialogRegion #answerLayer,#main #main_right #dialogRegion .answerDiv{border-top:1px solid #ECECEC;
    padding-top:30px;
    overflow:hidden;
    margin:10px 0;
    min-height:0;
    text-align:right}
@media all and (max-width:767px){#main #main_right #dialogRegion #answerLayer,#main #main_right #dialogRegion .answerDiv{margin-bottom:0}
}
#main #main_right #dialogRegion #answerLayer .ansL,#main #main_right #dialogRegion .answerDiv .ansL{text-align:left}
@media all and (max-width:767px){#main #main_right #dialogRegion #answerLayer .ansL,#main #main_right #dialogRegion .answerDiv .ansL{text-align:center}
}
#main #main_right #dialogRegion .externalContent{width:100%;
    height:435px}
#main #main_right #dialogRegion .ansL{float:left}
#main #main_right #dialogRegion .ansR{float:right}
@media all and (max-width:767px){#main #main_right #dialogRegion .ansR{margin-top:-15px;
    -webkit-transform:scaleY(-1);
    -ms-transform:scaleY(-1);
    transform:scaleY(-1)}
}
#main #main_right #dialogRegion .ansR button,#main #main_right #dialogRegion .ansR a{margin-left:20px}
@media all and (min-width:768px) and (max-width:1200px){#main #main_right #dialogRegion .ansR button,#main #main_right #dialogRegion .ansR a{margin-left:10px;
    width:250px}
}
@media all and (max-width:767px){#main #main_right #dialogRegion .ansR button,#main #main_right #dialogRegion .ansR a{margin-left:0;
    -webkit-transform:scaleY(-1);
    -ms-transform:scaleY(-1);
    transform:scaleY(-1)}
}
#main #main_right #dialogRegion .R-btn{margin-left:20px}
@media all and (min-width:768px) and (max-width:1200px){#main #main_right #dialogRegion .R-btn{margin-left:10px;
    width:250px}
}
@media all and (max-width:767px){#main #main_right #dialogRegion .R-btn{margin-left:0}
}
#main #main_right #dialogRegion .btn-outline{border:2px solid #037c8f;
    background:#fff;
    color:#037c8f}
#main #main_right #dialogRegion .btn-outline:hover{border:2px solid #05a4b5;
    background-color:#e6f7f9;
    color:#05a4b5}
#main #main_right #dialogRegion .btn-outline:active{background-color:#cdf0f4;
    border:2px solid #05a4b5;
    color:#05a4b5;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
#main #main_right #dialogRegion .btn-outline:focus{-webkit-box-shadow:0 0 0 1px rgba(3,124,143,.5);
    -moz-box-shadow:0 0 0 1px rgba(3,124,143,.5);
    box-shadow:0 0 0 1px rgba(3,124,143,.5)}

      .css-radio-checkbox input[type=radio],.css-radio-checkbox input[type=checkbox]{display:inline-block;
    -webkit-appearance:none;
    padding:0!important;
    margin:0!important;
    width:40px!important;
    height:42px!important;
    border:none!important;
    background:url() no-repeat 0 0;
    background-size:40px;
    -webkit-transition:none;
    transition:none;
    vertical-align:middle!important;
    position:relative!important}
.css-radio-checkbox input[type=radio]{background-position-y:-42px}
.css-radio-checkbox input[type=radio]:checked{background-position-y:0}
.css-radio-checkbox input[type=checkbox]{background-position-y:-84px}
.css-radio-checkbox input[type=checkbox]:checked{background-position-y:-126px}
.css-radio-checkbox input+label,.css-radio-checkbox input+span{margin:0!important;
    padding:10px;
    max-width:80%;
    vertical-align:middle}
.css-radio-checkbox .user-input-row{line-height:26px}
html,body{height:100%}
body.no-overflow{overflow:hidden}
body [class^=icon-],body [class*=icon-]{vertical-align:middle}
body .native-app-shown{display:none}
body.native-app{background-color:#fff!important;
    font-size:16px;
    font-weight:400}
body.native-app #footerLayer,body.native-app #main_left,body.native-app #scroll_fade,body.native-app #sectionTitle,body.native-app #nav_primary,body.native-app #nav_secondary,body.native-app .native-app-hidden{display:none!important}
body.native-app #skipId,body.native-app #mainBody{display:none}
body.native-app .native-app-shown{display:block!important}
body.native-app .standard-native-font{font-size:16px;
    font-weight:400}
body.native-app .standard-native-mobile-font{font-size:14px}
@media all and (max-width:767px){body.native-app{font-size:14px}
}
body.native-app #main{margin-top:0}
@media all and (max-width:767px){body.native-app #main{padding-left:0;
    padding-right:0}
}
body.native-app #main #main_right{width:100%}
body.native-app #main #main_right #dialogRegion{-webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
body.native-app #titleLayer{text-align:center}
body.native-app .legacy h1,body.native-app #dialogRegion.legacy span.title,body.native-app #dialogRegion.section_file.legacy .FnFtitle,body.native-app #modalContainer .FnFtitle{font-size:36px;
    font-weight:100;
    color:#6b6c72}
@media all and (max-width:767px){body.native-app .legacy h1,body.native-app #dialogRegion.legacy span.title,body.native-app #dialogRegion.section_file.legacy .FnFtitle,body.native-app #modalContainer .FnFtitle{font-weight:400;
    font-size:24px}
}
body.native-app .legacy input[type=radio],body.native-app .legacy input[type=checkbox]{display:inline-block;
    -webkit-appearance:none;
    padding:0!important;
    margin:0!important;
    width:40px!important;
    height:42px!important;
    border:none!important;
    background:url() no-repeat 0 0;
    background-size:40px;
    -webkit-transition:none;
    transition:none;
    vertical-align:middle!important;
    position:relative!important}
body.native-app .legacy input[type=radio]{background-position-y:-42px}
body.native-app .legacy input[type=radio]:checked{background-position-y:0}
body.native-app .legacy input[type=checkbox]{background-position-y:-84px}
body.native-app .legacy input[type=checkbox]:checked{background-position-y:-126px}
body.native-app .legacy input+label,body.native-app .legacy input+span{margin:0!important;
    padding:10px;
    max-width:80%;
    vertical-align:middle}
body.native-app .legacy .user-input-row{line-height:26px}
body.native-app .legacy input:not([type=radio]):not([type=checkbox]),body.native-app .legacy TEXTAREA,body.native-app .legacy select{font-size:16px;
    font-weight:400;
    height:40px}
@media all and (max-width:767px){body.native-app .legacy input:not([type=radio]):not([type=checkbox]),body.native-app .legacy TEXTAREA,body.native-app .legacy select{font-size:14px}
}
body.native-app .legacy p,body.native-app .legacy li,body.native-app .legacy table.grid td,body.native-app .legacy table.gridV2 td{font-size:16px;
    font-weight:400}
@media all and (max-width:767px){body.native-app .legacy p,body.native-app .legacy li,body.native-app .legacy table.grid td,body.native-app .legacy table.gridV2 td{font-size:14px}
}
@media all and (min-width:767px){body.native-app .legacy #answerLayer button{font-size:18px;
    height:42px;
    min-width:130px;
    padding:0 6px}
}
#createPage{margin:0;
    padding:0;
    background-color:#0082C3;
    width:100%;
    height:100%}
#createPage #createPageMessage{position:absolute;
    width:100%;
    height:100%;
    color:#FFF;
    text-align:center;
    font-family:Avenir;
    font-weight:100}
#createPage #createPageMessage .content{margin:20% 25px}
#createPage #createPageMessage .content .loader-icon{background:url() no-repeat center;
    background-size:50px;
    width:55px;
    height:55px;
    display:inline-block;
    vertical-align:top;
    margin-top:30px}
#createPage #createPageMessage .content>div.lineOne{font-size:38px;
    margin-bottom:30px}
@media all and (max-width:480px){#createPage #createPageMessage .content>div.lineOne{font-size:28px}
}
#createPage #createPageMessage .content>div.lineTwo{font-size:28px}
@media all and (max-width:480px){#createPage #createPageMessage .content>div.lineTwo{font-size:18px}
}
#ttoContainer{visibility:hidden;
    position:relative;
    overflow-x:hidden;
    min-height:100%}
#ttoContainer #contentOverlay{display:none}
body.device-ipad .legacy header{position:absolute!important}
#cannotClearOkBtnHolder{text-align:center;
    border-top:1px solid #ECECEC;
    padding:10px 10px;
    overflow:hidden}
#tools_home_close{border:none;
    background:0 0}
@media all and (max-width:767px){#toolbarLayer ul li:nth-child(2){display:none}
.legacy table.grid{width:100%!important}
.legacy table.grid img,.legacy table.gridV2 img{display:none}
.legacy table [class^=sep_solid]{width:auto!important}
#dialogRegion.legacy img{max-width:100%}
}
@media all and (max-width:767px){.legacy .ansL,.legacy .ansR,.legacy .navButtons{width:100%;
    text-align:center}
.legacy .ansL a,.legacy .ansR a,.legacy .navButtons a,.legacy .ansL button,.legacy .ansR button,.legacy .navButtons button{width:100%;
    padding-left:3px;
    padding-right:3px}
.legacy .ansL #Yes_00,.legacy .ansR #Yes_00,.legacy .navButtons #Yes_00,.legacy .ansL #No_00,.legacy .ansR #No_00,.legacy .navButtons #No_00{width:48.8%}
.legacy .ansL #Yes_00,.legacy .ansR #Yes_00,.legacy .navButtons #Yes_00{float:left}
.legacy .ansL #No_00,.legacy .ansR #No_00,.legacy .navButtons #No_00{float:right}
.legacy .ansL button{width:20%;
    margin-bottom:-15px}
.legacy .navButtons .L-btn{width:20%;
    margin-bottom:-15px}
}
@media all and (max-width:480px){.legacy .ansL button{width:30%}
}
body.no_session #ttoContainer{visibility:visible}
body.pgtype_tspg #dialogRegion.legacy #contentLayer{min-height:20px!important}
body.pgtype_tspg header #account #upgradeButton,body.pgtype_tspg #mobile_upgradeButton{display:none}
body.view_no_product_upgrade header #account #upgradeButton,body.view_no_product_upgrade #mobile_upgradeButton{display:none}
#signoutOverlay .modal-dialog{margin-top:100px}
#signoutOverlay div.modal-body>div{text-align:center}
#signoutOverlay div.modal-body>div img{vertical-align:middle}
#signoutOverlay div.modal-body>div span{padding-left:10px}
@media (min-width:768px){.modal-dialog.large{width:80%}
}
.modalScroll{overflow:visible;
    position:absolute}
.modal-header{border-bottom:none}
.modal-body .externalContent,#contentLayer .externalContent{width:100%;
    height:435px}
.modal-body .answerDiv,#contentLayer .answerDiv{text-align:right;
    padding:10px 10px;
    overflow:hidden;
    margin:0;
    min-height:0}
.modal-body .answerDiv .ansBackLg,#contentLayer .answerDiv .ansBackLg{float:left}
@media all and (max-width:767px){.modal-body .answerDiv .ansBackLg,#contentLayer .answerDiv .ansBackLg{float:none}
}
.modal-body .answerDiv .ansR,#contentLayer .answerDiv .ansR{float:right}
@media all and (max-width:767px){.modal-body .answerDiv .ansR,#contentLayer .answerDiv .ansR{float:none}
}
#ngPlayerContainer .btn{margin-left:5px;
    margin-right:5px}
#modalWrapper{z-index:1035}
div.modalTip{z-index:1050}
.mojoModalShow{display:block}
.mojoModalHide{display:none}
.defaultModalStyle,#modalContainer{box-shadow:0 0 40px #6D757A 0 1px #000;
    -moz-box-shadow:0 0 40px #6D757A 0 1px #000;
    -webkit-box-shadow:0 0 40px #6D757A 0 1px #000;
    -webkit-border-radius:3px;
    -webkit-background-clip:padding-box;
    -moz-border-radius:3px;
    -moz-background-clip:padding;
    border-radius:3px;
    background-clip:padding-box;
    display:none;
    min-width:310px;
    max-width:710px;
    min-height:200px;
    width:710px;
    height:auto;
    background-color:#FFF;
    border:1px solid #C2C2C2!important;
    padding:20px;
    z-index:1040}
.defaultModalStyle .answerDiv,#modalContainer .answerDiv{text-align:right;
    padding:30px 10px;
    overflow:hidden;
    margin:0;
    min-height:0}
.defaultModalStyle .externalContent,#modalContainer .externalContent{width:100%;
    height:435px}
.defaultModalStyle{position:absolute;
    top:50px;
    left:25%}
h1.modalHeader{font-size:25px;
    padding-bottom:18px;
    margin-bottom:20px}
.horizontalDivider{border-bottom:1px solid #D1D1D1;
    clear:both}
@media (max-width:768px){#modalContainer{width:95%;
    margin-left:2.5%;
    min-width:200px}
#modalWrapper{width:100%}
}
#modalClose,#bkmTitleClose,#taxExpertClose,#toolsCenter .closeBtn,.defaultCloseButton{background-image:url();
    cursor:pointer;
    height:38px;
    position:absolute;
    right:0;
    top:0;
    width:38px;
    background-repeat:no-repeat;
    background-position:center}
#modalClose:hover,#bkmTitleClose:hover,#taxExpertClose:hover,#toolsCenter .closeBtn:hover,.defaultCloseButton:hover{background-image:url()}
#modalBkg,#modalBgContainer,#screenTransitionBkg,#validationAlertModalBkg{position:fixed;
    top:0;
    left:0;
    z-index:1030;
    width:100%;
    height:100%;
    display:none;
    background-color:#272727;
    zoom:1;
    opacity:.3;
    -webkit-opacity:.3;
    -moz-opacity:.3;
    filter:alpha(opacity=30)}
#screenTransitionBkg{display:block;
    background:none!important}
DIV.MsgPopup{width:500px;
    moin-height:200px;
    position:absolute}
@media (max-width:600px){DIV.MsgPopup{width:100%!important;
    left:0!important}
.defaultModalStyle{left:0}
}
.legacy button{cursor:pointer}
.legacy .btn-blue,.legacy .ansLg,.legacy .ansSm,.legacy .guideMeLg,.legacy .ansLP,.legacy .lightBlue,.legacy .ansTabbar,.legacy .ansAqua{font-size:14px;
    color:#FFF;
    background:#037c8f;
    margin-bottom:5px}
.legacy .btn-blue:hover,.legacy .ansLg:hover,.legacy .ansSm:hover,.legacy .guideMeLg:hover,.legacy .ansLP:hover,.legacy .lightBlue:hover,.legacy .ansTabbar:hover,.legacy .ansAqua:hover{background:#0493a3}
.legacy .btn-blue:focus,.legacy .ansLg:focus,.legacy .ansSm:focus,.legacy .guideMeLg:focus,.legacy .ansLP:focus,.legacy .lightBlue:focus,.legacy .ansTabbar:focus,.legacy .ansAqua:focus{border:#FFEA00 solid 1px;
    outline:0;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .btn-blue:active,.legacy .ansLg:active,.legacy .ansSm:active,.legacy .guideMeLg:active,.legacy .ansLP:active,.legacy .lightBlue:active,.legacy .ansTabbar:active,.legacy .ansAqua:active{background:#05a4b5;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .btn-blue:hover,.legacy .ansLg:hover,.legacy .ansSm:hover,.legacy .guideMeLg:hover,.legacy .ansLP:hover,.legacy .lightBlue:hover,.legacy .ansTabbar:hover,.legacy .ansAqua:hover,.legacy .btn-blue:focus,.legacy .ansLg:focus,.legacy .ansSm:focus,.legacy .guideMeLg:focus,.legacy .ansLP:focus,.legacy .lightBlue:focus,.legacy .ansTabbar:focus,.legacy .ansAqua:focus,.legacy .btn-blue:active,.legacy .ansLg:active,.legacy .ansSm:active,.legacy .guideMeLg:active,.legacy .ansLP:active,.legacy .lightBlue:active,.legacy .ansTabbar:active,.legacy .ansAqua:active,.legacy .btn-blue:link,.legacy .ansLg:link,.legacy .ansSm:link,.legacy .guideMeLg:link,.legacy .ansLP:link,.legacy .lightBlue:link,.legacy .ansTabbar:link,.legacy .ansAqua:link,.legacy .btn-blue:visited,.legacy .ansLg:visited,.legacy .ansSm:visited,.legacy .guideMeLg:visited,.legacy .ansLP:visited,.legacy .lightBlue:visited,.legacy .ansTabbar:visited,.legacy .ansAqua:visited{color:#FFF}
.legacy .btn-link>i{display:inline-block;
    width:20px}
.legacy .ansR .ansLg,.legacy .navButtons .ansLg,.legacy .ansR .btn-important-action,.legacy .navButtons .btn-important-action,.legacy .ansR .purpleButton,.legacy .navButtons .purpleButton,.legacy .ansR .btn-link,.legacy .navButtons .btn-link{min-width:100px;
    display:inline-block;
    margin:2px 0 10px 0;
    background:#037c8f;
    color:#FFF;
    font-size:19px!important;
    border-radius:4px;
    text-align:center;
    padding:13px 24px;
    position:relative;
    border:2px solid transparent;
    font-family:Avenir,Helvetica,Arial,san-serif;
    font-weight:600;
    white-space:normal;
    line-height:24px}
@media all and (max-width:767px){.legacy .ansR .ansLg,.legacy .navButtons .ansLg,.legacy .ansR .btn-important-action,.legacy .navButtons .btn-important-action,.legacy .ansR .purpleButton,.legacy .navButtons .purpleButton,.legacy .ansR .btn-link,.legacy .navButtons .btn-link{font-weight:600}
}
.legacy .ansR .ansLg:hover,.legacy .navButtons .ansLg:hover,.legacy .ansR .btn-important-action:hover,.legacy .navButtons .btn-important-action:hover,.legacy .ansR .purpleButton:hover,.legacy .navButtons .purpleButton:hover,.legacy .ansR .btn-link:hover,.legacy .navButtons .btn-link:hover{background:#0493a3}
.legacy .ansR .ansLg:focus,.legacy .navButtons .ansLg:focus,.legacy .ansR .btn-important-action:focus,.legacy .navButtons .btn-important-action:focus,.legacy .ansR .purpleButton:focus,.legacy .navButtons .purpleButton:focus,.legacy .ansR .btn-link:focus,.legacy .navButtons .btn-link:focus{border:rgba(3,124,143,.5) solid 1px;
    outline:0;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .ansR .ansLg:active,.legacy .navButtons .ansLg:active,.legacy .ansR .btn-important-action:active,.legacy .navButtons .btn-important-action:active,.legacy .ansR .purpleButton:active,.legacy .navButtons .purpleButton:active,.legacy .ansR .btn-link:active,.legacy .navButtons .btn-link:active{background:#05a4b5;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .ansR .ansLg:hover,.legacy .navButtons .ansLg:hover,.legacy .ansR .btn-important-action:hover,.legacy .navButtons .btn-important-action:hover,.legacy .ansR .purpleButton:hover,.legacy .navButtons .purpleButton:hover,.legacy .ansR .btn-link:hover,.legacy .navButtons .btn-link:hover,.legacy .ansR .ansLg:focus,.legacy .navButtons .ansLg:focus,.legacy .ansR .btn-important-action:focus,.legacy .navButtons .btn-important-action:focus,.legacy .ansR .purpleButton:focus,.legacy .navButtons .purpleButton:focus,.legacy .ansR .btn-link:focus,.legacy .navButtons .btn-link:focus,.legacy .ansR .ansLg:active,.legacy .navButtons .ansLg:active,.legacy .ansR .btn-important-action:active,.legacy .navButtons .btn-important-action:active,.legacy .ansR .purpleButton:active,.legacy .navButtons .purpleButton:active,.legacy .ansR .btn-link:active,.legacy .navButtons .btn-link:active,.legacy .ansR .ansLg:link,.legacy .navButtons .ansLg:link,.legacy .ansR .btn-important-action:link,.legacy .navButtons .btn-important-action:link,.legacy .ansR .purpleButton:link,.legacy .navButtons .purpleButton:link,.legacy .ansR .btn-link:link,.legacy .navButtons .btn-link:link,.legacy .ansR .ansLg:visited,.legacy .navButtons .ansLg:visited,.legacy .ansR .btn-important-action:visited,.legacy .navButtons .btn-important-action:visited,.legacy .ansR .purpleButton:visited,.legacy .navButtons .purpleButton:visited,.legacy .ansR .btn-link:visited,.legacy .navButtons .btn-link:visited{color:#FFF}
.legacy .ansR .btn-important-action,.legacy .navButtons .btn-important-action{color:#FFF;
    background:#ff9331;
    -webkit-box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    -moz-box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    box-shadow:0 1px 1px 0 rgba(0,0,0,.75)}
.legacy .ansR .btn-important-action:hover,.legacy .navButtons .btn-important-action:hover{background:#ff8000}
.legacy .ansR .btn-important-action:focus,.legacy .navButtons .btn-important-action:focus{border:rgba(3,124,143,.5) solid 1px;
    outline:0;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .ansR .btn-important-action:active,.legacy .navButtons .btn-important-action:active{background:#ff6a00;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .ansR .btn-important-action:hover,.legacy .navButtons .btn-important-action:hover,.legacy .ansR .btn-important-action:focus,.legacy .navButtons .btn-important-action:focus,.legacy .ansR .btn-important-action:active,.legacy .navButtons .btn-important-action:active,.legacy .ansR .btn-important-action:link,.legacy .navButtons .btn-important-action:link,.legacy .ansR .btn-important-action:visited,.legacy .navButtons .btn-important-action:visited{color:#FFF}
.legacy .ansR .purpleButton,.legacy .navButtons .purpleButton{font-size:16px;
    color:#FFF;
    background:#4E2F8E;
    -webkit-box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    -moz-box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    margin-bottom:5px;
    text-transform:none;
    text-shadow:none}
.legacy .ansR .purpleButton:hover,.legacy .navButtons .purpleButton:hover{background:#836daf}
.legacy .ansR .purpleButton:focus,.legacy .navButtons .purpleButton:focus{border:#FFEA00 solid 1px;
    outline:0;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .ansR .purpleButton:active,.legacy .navButtons .purpleButton:active{background:#4E2F8E;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .ansR .purpleButton:hover,.legacy .navButtons .purpleButton:hover,.legacy .ansR .purpleButton:focus,.legacy .navButtons .purpleButton:focus,.legacy .ansR .purpleButton:active,.legacy .navButtons .purpleButton:active,.legacy .ansR .purpleButton:link,.legacy .navButtons .purpleButton:link,.legacy .ansR .purpleButton:visited,.legacy .navButtons .purpleButton:visited{color:#FFF}
.legacy .ansR .btn-link,.legacy .navButtons .btn-link{background:#d1d1d1;
    color:#666}
.legacy .ansR .btn-link:hover,.legacy .navButtons .btn-link:hover{background:#e1e1e1}
.legacy .ansR .btn-link:focus,.legacy .navButtons .btn-link:focus{border:#FFEA00 solid 1px;
    outline:0;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none;
    background-color:#d1d1d1}
.legacy .ansR .btn-link:active,.legacy .navButtons .btn-link:active{background:#b1b1b1;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .ansR .btn-link:hover,.legacy .navButtons .btn-link:hover,.legacy .ansR .btn-link:focus,.legacy .navButtons .btn-link:focus,.legacy .ansR .btn-link:active,.legacy .navButtons .btn-link:active,.legacy .ansR .btn-link:link,.legacy .navButtons .btn-link:link,.legacy .ansR .btn-link:visited,.legacy .navButtons .btn-link:visited{color:#666}
.legacy .btn-gray,.legacy .btn-gray-light,.legacy .ansBackLg,.legacy .ansRoyal,.legacy .guideMeSm,.legacy a[aria-label="Help Me Fix It"],.legacy .ansBackSm,.legacy .ansMCUI{font-size:14px;
    color:#6b6c72;
    background:#d4d7dc;
    -webkit-box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    -moz-box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    margin-bottom:5px;
    text-transform:none;
    text-shadow:none}
.legacy .btn-gray:hover,.legacy .btn-gray-light:hover,.legacy .ansBackLg:hover,.legacy .ansRoyal:hover,.legacy .guideMeSm:hover,.legacy a[aria-label="Help Me Fix It"]:hover,.legacy .ansBackSm:hover,.legacy .ansMCUI:hover{background:#babec5}
.legacy .btn-gray:focus,.legacy .btn-gray-light:focus,.legacy .ansBackLg:focus,.legacy .ansRoyal:focus,.legacy .guideMeSm:focus,.legacy a[aria-label="Help Me Fix It"]:focus,.legacy .ansBackSm:focus,.legacy .ansMCUI:focus{border:rgba(3,124,143,.5) solid 1px;
    outline:0;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .btn-gray:active,.legacy .btn-gray-light:active,.legacy .ansBackLg:active,.legacy .ansRoyal:active,.legacy .guideMeSm:active,.legacy a[aria-label="Help Me Fix It"]:active,.legacy .ansBackSm:active,.legacy .ansMCUI:active{background:#a1a5ab;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .btn-important-action{font-size:16px;
    color:#FFF;
    background:#ff9331;
    -webkit-box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    -moz-box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    margin-bottom:5px;
    text-transform:none;
    text-shadow:none}
.legacy .btn-important-action:hover{background:#ff8000}
.legacy .btn-important-action:focus{border:rgba(3,124,143,.5) solid 1px;
    outline:0;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .btn-important-action:active{background:#ff6a00;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .btn-important-action:hover,.legacy .btn-important-action:focus,.legacy .btn-important-action:active,.legacy .btn-important-action:link,.legacy .btn-important-action:visited{color:#FFF}
.legacy .ansL .ansBackLg,.legacy .navButtons .ansBackLg,.legacy .ansL .L-btn,.legacy .navButtons .L-btn{font-family:Avenir,Helvetica,Arial,san-serif;
    font-weight:600;
    color:#037c8f!important;
    font-size:19px!important;
    display:inline-block;
    position:relative;
    margin:2px 0 10px;
    padding:13px 24px;
    text-align:left;
    left:0;
    background:#fff;
    -webkit-box-shadow:none!important;
    -moz-box-shadow:none!important;
    box-shadow:none!important}
.legacy .ansL .ansBackLg:hover,.legacy .navButtons .ansBackLg:hover,.legacy .ansL .L-btn:hover,.legacy .navButtons .L-btn:hover{background:#eceef1}
.legacy .ansL .ansBackLg:active,.legacy .navButtons .ansBackLg:active,.legacy .ansL .L-btn:active,.legacy .navButtons .L-btn:active{background:#d4d7dc}
.legacy .ansL .ansBackLg:focus,.legacy .navButtons .ansBackLg:focus,.legacy .ansL .L-btn:focus,.legacy .navButtons .L-btn:focus{border:rgba(3,124,143,.5) solid 1px}
.legacy .ansL .ansBackLg:before,.legacy .navButtons .ansBackLg:before,.legacy .ansL .L-btn:before,.legacy .navButtons .L-btn:before{font-family:tt-icon-font!important;
    content:"d";
    position:relative;
    top:2px;
    left:-3px;
    font-size:16px}
@media all and (max-width:767px){.legacy .ansL .ansBackLg,.legacy .navButtons .ansBackLg,.legacy .ansL .L-btn,.legacy .navButtons .L-btn{width:100%;
    padding-top:6px;
    text-align:center;
    margin-top:15px}
}
.legacy #gdes-taximport-widget-container .btn-default,.legacy #gdes-taximport-widget-container .btn-primary{border-color:transparent;
    font-size:18px;
    margin-bottom:9px;
    padding:8px}
@media all and (max-width:767px){.legacy #gdes-taximport-widget-container .ansL .btn-default{width:30%}
}
.legacy .w2i-skipimport-form .btn-default,.legacy .w2i-skipimport-form .btn-primary{width:100%;
    white-space:normal}
.legacy .ansGrn{font-size:14px;
    color:#FFF;
    background:#48de5d;
    -webkit-box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    -moz-box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    margin-bottom:5px;
    text-transform:none;
    text-shadow:none}
.legacy .ansGrn:hover{background:#8CE492}
.legacy .ansGrn:focus{border:#FFEA00 solid 1px;
    outline:0;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .ansGrn:active{background:#28B63C;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .ansGrn:hover,.legacy .ansGrn:focus,.legacy .ansGrn:active,.legacy .ansGrn:link,.legacy .ansGrn:visited{color:#FFF}
.legacy .ansSm,.legacy .ansLP,.legacy .ansBackSm,.legacy .ansRoyal{margin-bottom:5px}
.legacy .screenid_prepare_state_home .ansSm{min-width:88px;
    font-size:14px}
@media all and (max-width:767px){.legacy .screenid_prepare_state_home .ansSm{padding:5px;
    min-width:70px}
}
.legacy .screenid_prepare_state_home .ansMCUI{min-width:88px}
@media all and (max-width:767px){.legacy .screenid_prepare_state_home .ansMCUI{padding:5px;
    min-width:70px}
}
.legacy .btn.disabled,.legacy .btn[disabled]{cursor:default;
    background-image:none;
    text-shadow:none;
    position:relative;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none}
.legacy .btn.disabled:hover,.legacy .btn[disabled]:hover{text-decoration:none;
    color:#0059AC}
.legacy .btn.locked{color:#0059AC}
.legacy .btn.submit{position:relative}
.legacy .btn-medium .loadingEffect{width:20px;
    left:40%}
.legacy .btn-medium .loadingEffect img{height:20px;
    width:20px}
@media (min-width:767px){.legacy .btn-large .loadingEffect{width:20px;
    left:40%}
.legacy .btn-large .loadingEffect img{height:20px;
    width:20px}
}
.legacy .loadingEffect{top:16%;
    left:0;
    width:100%;
    position:absolute!important}
.legacy .loadingEffect img{height:32px;
    width:32px}
.legacy .lt-ie9 .btn:hover{filter:none}
.legacy .ie9 .btn{filter:none}
.legacy .ansBackLg,.legacy .ansLg,.legacy .guideMeLg,.legacy .btn-important-action,.legacy .purpleButton{width:auto;
    width:initial}
.legacy a.ansTabbar{display:inline-block;
    -webkit-border-radius:3px;
    -webkit-background-clip:padding-box;
    -moz-border-radius:3px;
    -moz-background-clip:padding;
    border-radius:3px;
    background-clip:padding-box;
    color:#3377CD;
    text-shadow:none;
    font-size:.92em;
    padding:4px 8px;
    margin-right:5px}
.legacy a.ansTabbar span{display:inline-block}
.legacy a.lightBlue:first-child,.legacy a.ansTabbar:first-child{width:auto}
.legacy #extcont{font-size:0}
.legacy DIV.FormsModeArea{position:absolute;
    width:100%;
    height:40px;
    z-index:1040;
    left:0;
    top:0;
    visibility:hidden}
.legacy DIV.BeaconArea{position:absolute;
    top:0;
    left:0;
    width:10px;
    height:10px;
    visibility:hidden}
.legacy IFRAME.Response{position:absolute;
    top:0;
    left:0;
    width:100px;
    height:100px;
    visibility:hidden}
.legacy IFRAME.Beacon{position:absolute;
    top:1px;
    left:1px;
    width:1px;
    height:1px;
    visibility:hidden}
.legacy table label{font-weight:inherit}
.legacy table label.bold{font-weight:700}
.legacy table.grid{table-layout:auto}
.legacy table.gridV2{table-layout:auto;
    width:100%}
.legacy table.grid td{padding-bottom:4px;
    padding-top:4px}
.legacy td{vertical-align:middle}
.legacy td[valign=top]{vertical-align:top}
.legacy td[valign=center]{vertical-align:middle}
.legacy td.colFirst{padding:6px 2px 0 0}
.legacy td.colLast{padding:6px 0 0 2px}
.legacy td.colPad{padding:6px 2px 0 2px}
.legacy td.colNoPad{padding:0}
.legacy td.colNoVerticalPad{padding-bottom:0;
    padding-top:0;
    user-select:none}
.legacy td.colDbg:hover{background-color:rgba(0,96,165,.5)}
.legacy td.colLast>.btn{float:right;
    min-width:40px;
    text-align:center}
@media all and (max-width:767px){.legacy td.colLast>.btn{min-width:70px}
}
.legacy #contentLayer[data-sid=S1040PERbfdi7329] td.colLast>.btn,.legacy #contentLayer[data-sid=S1040PERbfdi7332] td.colLast>.btn{float:none}
.legacy .lndtbltabs{padding:2px 0;
    color:#FFF}
.legacy .noBackground{background:0 0;
    filter:none}
.legacy .maxHeight100{max-height:100%}
.legacy .overflowHidden{overflow:hidden}
.legacy #FORM_CANVAS_formLayer .formTextBox{font-size:12px!important;
    padding:0 1px 0!important}
.legacy DIV.debugRegion{display:none;
    visibility:hidden}
.legacy .clearDiv{clear:both;
    width:0;
    height:0;
    margin:0;
    padding:0}
@media all and (-ms-high-contrast:none){.legacy #FORM_CANVAS_formLayer .formTextBox{height:22px!important;
    line-height:20px!important;
    padding:0 1px 0 px!important;
    font-size:12px!important}
}
.screenid_prepare_state_home td.colLast>.btn{float:left;
    min-width:88px}
@media all and (max-width:767px){.screenid_prepare_state_home td.colLast>.btn{min-width:70px}
}
.screenid_upload input[type=file]{width:100%}
#rsp,#bea,#bea2,#qkn{position:absolute;
    top:0;
    left:0;
    width:10px;
    height:10px;
    visibility:hidden}
#dashboardHomeLink{display:none}
#ponPrintIframe{display:none}
.legacy.screenid_S1040PERbfdi7886 table>tbody>tr>td>table>tbody>tr>td>table>tbody>tr>td{width:auto!important}

      footer.legacy{text-align:center;
    color:#909090}
footer.legacy div.links{margin:0 auto;
    padding-top:30px;
    padding-bottom:10px;
    width:300px;
    position:relative;
    font-size:12px;
    line-height:16px}
footer.legacy div.links a{cursor:pointer;
    font-size:12px;
    border-left:solid 1px #909090;
    padding:0 5px}
footer.legacy div.links a:first-child{border:none}
footer.legacy #promoLayer{padding-top:30px}

      .css-radio-checkbox input[type=radio],.css-radio-checkbox input[type=checkbox]{display:inline-block;
    -webkit-appearance:none;
    padding:0!important;
    margin:0!important;
    width:40px!important;
    height:42px!important;
    border:none!important;
    background:url() no-repeat 0 0;
    background-size:40px;
    -webkit-transition:none;
    transition:none;
    vertical-align:middle!important;
    position:relative!important}
.css-radio-checkbox input[type=radio]{background-position-y:-42px}
.css-radio-checkbox input[type=radio]:checked{background-position-y:0}
.css-radio-checkbox input[type=checkbox]{background-position-y:-84px}
.css-radio-checkbox input[type=checkbox]:checked{background-position-y:-126px}
.css-radio-checkbox input+label,.css-radio-checkbox input+span{margin:0!important;
    padding:10px;
    max-width:80%;
    vertical-align:middle}
.css-radio-checkbox .user-input-row{line-height:26px}

      .css-radio-checkbox input[type=radio],.css-radio-checkbox input[type=checkbox]{display:inline-block;
    -webkit-appearance:none;
    padding:0!important;
    margin:0!important;
    width:40px!important;
    height:42px!important;
    border:none!important;
    background:url() no-repeat 0 0;
    background-size:40px;
    -webkit-transition:none;
    transition:none;
    vertical-align:middle!important;
    position:relative!important}
.css-radio-checkbox input[type=radio]{background-position-y:-42px}
.css-radio-checkbox input[type=radio]:checked{background-position-y:0}
.css-radio-checkbox input[type=checkbox]{background-position-y:-84px}
.css-radio-checkbox input[type=checkbox]:checked{background-position-y:-126px}
.css-radio-checkbox input+label,.css-radio-checkbox input+span{margin:0!important;
    padding:10px;
    max-width:80%;
    vertical-align:middle}
.css-radio-checkbox .user-input-row{line-height:26px}
.legacy INPUT:not([type=radio]):not([type=checkbox]),.legacy TEXTAREA{font-size:1.05em;
    border:1px solid #B9BCBE;
    padding:2px 6px 0;
    color:#1F1F1F;
    resize:none}
.legacy INPUT:not([type=radio]):not([type=checkbox]):not([disabled]):hover,.legacy TEXTAREA:not([disabled]):hover{border:1px solid #09D}

.legacy INPUT{-webkit-transition:all 100ms;
    -moz-transition:all 100ms;
    -o-transition:all 100ms;
    transition:all 100ms;
    -webkit-border-radius:2px;
    -webkit-background-clip:padding-box;
    -moz-border-radius:2px;
    -moz-background-clip:padding;
    border-radius:2px;
    background-clip:padding-box;
    height:32px}
.legacy INPUT.defaultText{color:#AAA;
    font-style:italic;
    font-size:.84em}
.legacy INPUT.readonly{padding-left:3px;
    border:0;
    color:#336;
    background:0 0;
    box-shadow:none}
.legacy INPUT.editErr,.legacy INPUT .inaccessible{border:1px solid #D12B37}
.legacy INPUT.editErr:focus,.legacy INPUT .inaccessible:focus{-webkit-box-shadow:inset 0 0 2px #fce0e2;
    -moz-box-shadow:inset 0 0 2px #fce0e2;
    box-shadow:inset 0 0 2px #fce0e2;
    border:1px solid #D12B37}
.legacy INPUT.editErr:not([disabled]):hover,.legacy INPUT .inaccessible:not([disabled]):hover{border:1px solid #D12B37}
.legacy INPUT.dollar{background-image:url();
    background-repeat:no-repeat;
    background-position:5px center;
    padding-left:15px}
.legacy INPUT:disabled{background-color:#F2F2F2;
    color:#5B5B5B;
    -moz-box-shadow:none;
    -webkit-box-shadow:none;
    box-shadow:none}
.legacy INPUT:not(:focus):not([disabled]):hover{-moz-box-shadow:none;
    -webkit-box-shadow:none;
    box-shadow:none}
.legacy INPUT[type=radio],.legacy INPUT[type=checkbox]{height:13px}
.legacy INPUT[type=file]{height:auto}
@media (-webkit-min-device-pixel-ratio:0) and (max-width:767px){.legacy input[type=radio],.legacy input[type=checkbox]{display:inline-block;
    -webkit-appearance:none;
    padding:0!important;
    margin:0!important;
    width:40px!important;
    height:42px!important;
    border:none!important;
    background:url() no-repeat 0 0;
    background-size:40px;
    -webkit-transition:none;
    transition:none;
    vertical-align:middle!important;
    position:relative!important}
.legacy input[type=radio]{background-position-y:-42px}
.legacy input[type=radio]:checked{background-position-y:0}
.legacy input[type=checkbox]{background-position-y:-84px}
.legacy input[type=checkbox]:checked{background-position-y:-126px}
.legacy input+label,.legacy input+span{margin:0!important;
    padding:10px;
    max-width:80%;
    vertical-align:middle}
.legacy .user-input-row{line-height:26px}
}
.legacy SELECT{-webkit-border-radius:2px;
    -webkit-background-clip:padding-box;
    -moz-border-radius:2px;
    -moz-background-clip:padding;
    border-radius:2px;
    background-clip:padding-box;
    font-size:.92em;
    border:1px #B3B3B3 solid;
    padding:4px 0}
@media all and (max-width:767px){.legacy SELECT{height:38px}
.legacy SELECT[size]{height:auto}
}
.legacy SELECT:focus{background-color:#DCF1FB;
    outline:0}
.legacy SELECT.lst{height:34px}
@media all and (max-width:767px){.legacy SELECT.lst{height:38px;
    width:100%;
    min-width:55px}
}
.legacy SELECT.sz{height:auto!important}
@media (-webkit-min-device-pixel-ratio:0){.legacy select:not([size]).form-control,.legacy select[size='1'].form-control{-webkit-appearance:none;
    padding:2px 25px 2px 6px;
    background:url() no-repeat right 6px center #fff;
    background-size:12px}
}
@media (-webkit-min-device-pixel-ratio:0) and (max-width:767px){.legacy select:not([size]).form-control,.legacy select[size='1'].form-control{padding:2px 25px 2px 3px}
}
@media (-webkit-min-device-pixel-ratio:0){.legacy select:not([size]),.legacy select[size='1']{-webkit-appearance:none;
    padding:2px 25px 2px 6px;
    background:url() no-repeat right 6px center #fff;
    background-size:12px}
}
@media (-webkit-min-device-pixel-ratio:0) and (max-width:767px){.legacy select:not([size]),.legacy select[size='1']{padding:2px 25px 2px 3px}
}

      #gps{color:#888;
    text-align:left}
#gps p{text-align:left}
#gps .intro_hero{color:#999;
    font-style:italic;
    font-size:.9em;
    text-align:center}
#gps .intro_hero img{width:80%;
    padding-bottom:30px}
#gps .ul_adjustment{float:none;
    padding:2px 0 16px;
    margin-left:270px}
#gps .ul_adjustment_state{margin-left:305px}
#gps .ul_adjustment li{padding-top:0;
    padding-bottom:2px}
#gps .h1_adjustment{color:#333;
    font-weight:lighter;
    font-size:30px;
    margin:13px 0 24px;
    text-align:center}
#gps .img_adjustment{margin:0}
@media (max-width:480px){#gps .ul_adjustment{margin-left:10px!important;
    width:100%!important}
}
@media (min-width:480px) and (max-width:767px){#gps .ul_adjustment{margin-left:21%!important;
    width:100%!important}
#gps .ul_adjustment_state{margin-left:28%!important}
}
@media (min-width:768px) and (max-width:991px){#gps .ul_adjustment{margin-left:158px!important;
    width:100%!important}
#gps .ul_adjustment_state{margin-left:185px!important}
}
@media (max-width:768px){#gps img{display:block;
    margin-left:auto;
    margin-right:auto;
    padding-bottom:0}
#gps .main-logo{width:115px;
    padding-bottom:20px}
#gps .banner{width:250px;
    margin-bottom:20px}
#gps p{text-align:center}
#gps .ul_adjustment{padding-bottom:0;
    margin-left:186px;
    width:75%}
#gps .ul_adjustment_state{margin-left:140px}
#gps .ul_adjustment li{padding:10px 0 5px;
    line-height:20px}
}
@media (min-width:992px) and (max-width:1200px){#gps .ul_adjustment{margin-left:185px;
    width:95%}
#gps .ul_adjustment_state{margin-left:215px}
}

      span.newItemBubble{background:#0077c5;
    color:#fff;
    padding:2px 5px;
    font-size:9px;
    font-weight:400;
    position:relative;
    top:-9px;
    border-radius:3px}
@media all and (max-width:992px){span.newItemBubble{background:#0097e6}
}
span.absolute0{margin-right:10px}
span.absolute0.textspan{margin-right:5px}
.absolute0.newarrow{background-image:url();
    background-position:0 0;
    background-repeat:no-repeat;
    width:29px;
    height:15px}
.absolute0,.absolute0_block{display:none}
.defaultPopup{-moz-box-shadow:0 0 40px #65A5CC;
    -webkit-box-shadow:0 0 40px #65A5CC;
    box-shadow:0 0 40px #65A5CC;
    background-color:#FFF;
    -moz-border-radius:3px;
    -webkit-border-radius:3px;
    border-radius:3px;
    border:1px solid #ABCBDF;
    padding:20px;
    z-index:100}
#toolsCenter.legacy{min-height:100px;
    min-width:200px;
    text-align:left}
#toolsCenter.legacy #btnSave{min-width:105px;
    width:auto}
@media (max-width:767px){#toolsCenter.legacy #btnSave{min-width:80px}
}
#toolsCenter.legacy .modal-dialog{width:630px;
    margin-top:10%}
#toolsCenter.legacy .innerBox{margin:0 auto}
#toolsCenter.legacy .scrollOuterContainer{overflow:hidden;
    margin:0 auto;
    position:relative}
#toolsCenter.legacy .scrollBox{position:relative}
#toolsCenter.legacy #tpsrch{width:100%}
#toolsCenter.legacy h1{text-align:center;
    margin:0}
#toolsCenter.legacy .homeScreen{padding:20px}
#toolsCenter.legacy .homeScreen .content{margin:0 auto;
    padding-top:15px;
    padding-bottom:8px;
    border-bottom:solid 1px #e5e5e5;
    overflow:hidden;
    width:80%}
#toolsCenter.legacy .homeScreen .content .row{text-align:center;
    margin:0}
#toolsCenter.legacy .homeScreen .content .toolcenterBtnInner{position:absolute;
    top:0;
    left:0;
    cursor:pointer;
    text-align:center;
    padding-top:14px;
    padding-bottom:15px;
    height:80px;
    color:#666;
    font-size:13px;
    border:1px solid #bbb;
    box-shadow:0 1px 3px #ddd;
    overflow:hidden;
    background:#fcfcfc;
    background:-moz-linear-gradient(top,#fcfcfc 0,#ededed 100%);
    background:-webkit-gradient(linear,left top,left bottom,color-stop(0,#fcfcfc),color-stop(100%,#ededed));
    background:-webkit-linear-gradient(top,#fcfcfc 0,#ededed 100%);
    background:-o-linear-gradient(top,#fcfcfc 0,#ededed 100%);
    background:-ms-linear-gradient(top,#fcfcfc 0,#ededed 100%);
    background:linear-gradient(top,#fcfcfc 0,#ededed 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#ededed', GradientType=0);
    -webkit-transition:all 280ms linear;
    -moz-transition:all 280ms linear;
    -o-transition:all 280ms linear;
    -ms-transition:all 280ms linear;
    transition:all 280ms linear}
#toolsCenter.legacy .homeScreen .content .toolcenterBtnInner:hover{background:#ececec;
    background:-moz-linear-gradient(top,#ececec 0,#dadada 100%);
    background:-webkit-gradient(linear,left top,left bottom,color-stop(0,#ececec),color-stop(100%,#dadada));
    background:-webkit-linear-gradient(top,#ececec 0,#dadada 100%);
    background:-o-linear-gradient(top,#ececec 0,#dadada 100%);
    background:-ms-linear-gradient(top,#ececec 0,#dadada 100%);
    background:linear-gradient(top,#ececec 0,#dadada 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ececec', endColorstr='#dadada', GradientType=0)}
#toolsCenter.legacy .homeScreen .content .toolcenterBtnInner:hover .toolIcon{-webkit-animation:moveFromTop 280ms linear;
    -moz-animation:moveFromTop 280ms linear;
    -ms-animation:moveFromTop 280ms linear}
#toolsCenter.legacy .toolcenter_back i{position:relative;
    top:2px}
#toolsCenter.legacy .toolcenter_back:hover{text-decoration:none}
#toolsCenter.legacy .toolScreen{display:none;
    padding:20px}
#toolsCenter.legacy .toolScreen .content{min-height:293px;
    height:auto;
    margin:15px auto 0;
    position:relative}
#toolsCenter.legacy .toolScreen #WDIE{position:relative}
#toolsCenter.legacy .toolScreen #classic_confirm{height:293px}
#toolsCenter.legacy .toolScreen #classic_confirm p{margin-top:30px}
#toolsCenter.legacy .toolScreen #classic_confirm .answers{position:absolute;
    bottom:15px}
#toolsCenter.legacy .toolScreen #classic_confirm .answers button{width:90px;
    height:32px;
    line-height:32px}
#toolsCenter.legacy .toolScreen #classic_confirm .answers button#stay_rue{margin-left:170px}
#toolsCenter.legacy .toolScreen #topicSearchList{height:200px;
    border:solid 1px;
    overflow:auto}
#toolsCenter.legacy .toolScreen #topicSearchList button{margin-bottom:5px;
    display:block;
    width:100%;
    text-align:left;
    border:none;
    background-color:#eee}
#toolsCenter.legacy .links{padding:12px;
    margin:0 auto;
    text-align:left;
    border-width:1px 0 0;
    border-style:solid;
    border-color:#fff;
    width:85%}
#toolsCenter.legacy .links .linkHeader{font-size:15px;
    font-weight:700;
    margin-bottom:5px}
#toolsCenter.legacy .spacer{width:100%;
    height:4px}
#toolsCenter.legacy .btn{margin:10px 0 10px 0;
    padding-top:10px;
    border:0;
    min-width:190px}
#toolsCenter.legacy .btn i{position:relative;
    top:4px;
    font-size:30px;
    padding-right:10px}
@media all and (max-width:767px){#toolsCenter.legacy .modal-dialog{width:auto}
#toolsCenter.legacy .toolTriggerButtons{text-align:center}
#toolsCenter.legacy .btn{width:100%;
    min-width:140px}
#toolsCenter.legacy .btn i{padding-right:0}
}
#signoutOverlay.legacy{z-index:1045}
#sessionTimeoutDialog.legacy{z-index:1050}
#sessionTimeoutDialog.legacy .modal-dialog{width:450px}
#sessionTimeoutDialog.legacy .modal-dialog .session-timeout-content{margin:0 auto;
    background-color:#fff;
    border-radius:6px;
    padding-top:15px;
    padding-bottom:8px;
    border-bottom:solid 1px #e5e5e5;
    overflow:hidden;
    padding-left:25px;
    padding:20px}
#sessionTimeoutDialog.legacy .modal-dialog .session-timeout-content .timeout-information .session-timeout-icon{float:left;
    background:url() no-repeat 0 0;
    width:36px;
    height:40px;
    margin:5px 0 0 12px}
#sessionTimeoutDialog.legacy .modal-dialog .session-timeout-content .timeout-information .timeout-message{float:left;
    width:320px;
    margin-left:14px}
#sessionTimeoutDialog.legacy .modal-dialog .session-timeout-content .timeout-information .timeout-message P:first-child{margin-top:0}
#sessionTimeoutDialog.legacy .modal-dialog .session-timeout-content .timeout-information .timeout-message .action-question{margin-left:42px}
#sessionTimeoutDialog.legacy .modal-dialog .session-timeout-content .action-buttons{display:block;
    margin-left:20%}
#sessionTimeoutDialog.legacy .modal-dialog .session-timeout-content .action-buttons .btn:first-child{margin-right:6px}
.legendRow{margin-bottom:5px}
.legendBullet{border:2px solid #fff;
    -moz-border-radius:3px;
    -webkit-border-radius:3px;
    border-radius:3px;
    float:left;
    height:8px;
    margin-right:8px;
    width:8px}
.legendText{font-size:11px}
.header img.logo{margin-top:10px}
DIV.TopicList{text-align:left;
    font-size:1.1em;
    margin:30px 0 10px 0;
    overflow:auto;
    border:2px solid #eee;
    background-color:#EFF4F5}
div.TopicList.jstree li[ischecked=false].jstree-checked>a>.jstree-checkbox{background-position:-2px -19px}
DIV.TopicListFooter{padding-top:7px;
    text-align:left}
DIV.TopicListFooterButton{padding:3px 6px;
    color:#fff;
    font-size:13px;
    background-color:#888;
    cursor:pointer;
    display:inline-block;
    ;
    -moz-border-radius:4px;
    -webkit-border-radius:4px;
    border-radius:4px;
    margin-right:5px}
DIV.TopicListFooterButton:hover{background-color:#555}
DIV.TopicListFooterLIcon{background-color:#eee;
    display:inline-block;
    ;
    font-size:13px;
    font-weight:700;
    color:#555;
    line-height:9px;
    width:8px;
    text-align:center;
    padding:0 1px;
    -moz-border-radius:5px;
    -webkit-border-radius:5px;
    border-radius:5px;
    -moz-box-shadow:0 0 3px #333;
    -webkit-box-shadow:0 0 3px #333;
    box-shadow:0 0 3px #333}
DIV.TopicListSpinnerContainer{width:100%;
    height:100%;
    overflow:hidden;
    text-align:center}
DIV.TopicListSpinnerInner{margin:100px 10px 60px;
    vertical-align:middle;
    font-size:16px;
    font-weight:700;
    text-shadow:0 0 3px #aaa;
    color:#555;
    width:50px}
DIV.topicSearch{position:relative;
    width:100%;
    height:100%;
    overflow:hidden}
DIV.topicSearchMsg{position:absolute;
    top:12px}
DIV.topicSearchBody{display:none}
DIV.topicSearchWaiting{color:#666;
    font-size:18px;
    font-weight:700;
    position:relative;
    top:25%;
    left:25%;
    background-color:#fff;
    padding:10px}
DIV.topicSearchWaiting img{vertical-align:middle;
    margin-right:10px;
    width:50px}
DIV.topicSearchGo{text-align:center;
    margin-top:5px}
DIV.PurchaseViewBase{position:relative;
    width:100%;
    height:100%;
    overflow:hidden;
    border-top:solid 1px #c6c6c6}
DIV.ErrorLayer{position:relative;
    width:100%;
    height:100%;
    padding:40px;
    font-size:1.23em;
    line-height:17px;
    text-align:center;
    text-indent:0;
    overflow:hidden}
DIV.PurchaseTable{position:relative;
    width:100%;
    height:60%;
    min-height:200px;
    margin-top:20px;
    overflow:auto;
    overflow:-moz-scrollbars-none;
    overflow:-moz-scrollbars-vertical;
    overflow-y:auto;
    border-bottom:solid 5px #6f6f6f}
DIV.PurchaseTotal{position:relative;
    width:100%;
    height:30%;
    overflow:hidden;
    -moz-box-shadow:0 3px 5px #bbb inset;
    -webkit-box-shadow:0 3px 5px #bbb inset;
    box-shadow:0 3px 5px #bbb inset}
DIV.PurchaseFooter{position:relative;
    width:100%;
    height:10%;
    text-align:left;
    padding-top:4px;
    padding-left:5px}
DIV.PurchaseTableRow{padding-bottom:9px;
    padding-top:9px;
    height:28px}
TABLE.PurchaseTableRow{width:100%}
.SelectedItemTitle{padding-left:13px;
    font-size:1.07em;
    line-height:14px;
    color:#000;
    padding-bottom:9px;
    padding-top:9px;
    height:28px}
.SelectedItemPrice{text-align:right;
    font-size:1.07em;
    line-height:14px;
    color:#000;
    padding-bottom:9px;
    padding-top:9px;
    height:28px}
.PaidText{text-align:right;
    font-size:.84em;
    line-height:14px;
    color:#000;
    padding-right:8px;
    padding-bottom:9px;
    padding-top:9px;
    height:28px}
.IncludedFeaturesHeader{text-align:left;
    font-size:.84em;
    font-weight:700;
    color:#000}
.IncludedFeaturesText{text-align:left;
    font-size:.84em;
    color:#000}
.TotalsHeaderText{font-size:1.07em;
    line-height:17px;
    padding-left:13px;
    padding-top:1px;
    padding-bottom:1px}
.TotalsAmountText{font-size:1.07em;
    line-height:17px;
    padding-top:1px;
    padding-bottom:1px}
.SalesTaxFooter{font-size:.84em}
.SalesTaxAsteric{color:#c30;
    font-size:1.15em;
    font-weight:700}
.SalesTaxAstericSmall{padding-left:13px;
    color:#c30;
    font-size:.84em}
div.PurchaseViewError{position:absolute;
    left:0;
    top:0;
    padding:40px;
    font-size:1.23em;
    width:80%;
    margin:auto}
a.HelpLinkText{font-size:.84em;
    color:#555;
    text-decoration:underline;
    cursor:pointer}
.bookmarkPopup{margin-top:30px}
.bookmarkArrow{width:0;
    height:0;
    border-left:20px solid transparent;
    border-right:20px solid transparent;
    border-bottom:20px solid #FFF;
    display:inline-block;
    position:absolute;
    top:-20px;
    left:100px}
#bkmTop .bkmTitle{font-size:16px;
    font-weight:700;
    color:#333;
    letter-spacing:.4px;
    text-shadow:0 1px 0 #fff;
    text-align:left}
#bkmAddScreen{display:none;
    margin:15px 0}
#bkmAddScreenMsg{display:none;
    background-color:#e14962;
    font-size:11px;
    font-weight:700;
    color:#fff;
    padding:5px 10px;
    margin-bottom:5px}
#bkmAddScreen input,#bkmAddScreen textarea,#bkmAddScreen input:focus{width:100%;
    font-size:1em;
    font-weight:400;
    margin:0;
    height:28px}
#bkmAddScreen .addScreenName{width:48%;
    text-align:left;
    float:left;
    margin-bottom:10px}
#bkmAddScreen .addScreenDesc{width:48%;
    margin-bottom:6px;
    text-align:left;
    float:right}
#bkmAddScreen .label{font-size:.9em;
    color:#8a8a8a;
    padding-bottom:3px}
#savedFlagsLabel{font-size:1.01em;
    font-weight:700;
    color:#5C5C5C}
#noFlags{margin:10px 0;
    height:40px;
    border-top:1px solid #d1d1d1}
#noFlagsLabel{float:left;
    padding:6px 9px;
    font-size:1.01em;
    color:#777;
    height:35px;
    line-height:35px}
#bkmShowAll{font-size:.9em;
    font-weight:700;
    display:none;
    padding:3px 10px;
    cursor:pointer;
    color:#444;
    display:inline-block;
    text-align:center}
#bkmShowAll:hover{background-color:#e2e2e2}
#bkmShowAll div{background-image:url();
    background-repeat:no-repeat;
    background-position:0;
    padding-left:22px;
    height:18px;
    line-height:18px;
    text-shadow:1px 1px 0 #eee}
#bkmContent{font-size:.9em;
    font-weight:700;
    overflow:hidden;
    margin:10px 0}
#bkmContent .bkmEntry{height:40px;
    border-top:1px solid #d1d1d1}
#bkmContent .bkmEntry:hover{-webkit-transition:background-color .4s ease-in;
    -moz-transition:background-color .4s ease-in;
    transition:background-color .4s ease-in;
    background-color:#f0f8fb}
#bkmContent .bkmEntry .title{color:#09f;
    font-size:1em}
#bkmContent .bkmEntry .desc{line-height:16px;
    font-size:12px;
    color:#777;
    font-weight:400}
#bkmContent .bkmEntry .left{padding:6px 9px;
    float:left;
    text-align:left;
    width:175px;
    cursor:pointer}
#bkmContent .bkmEntry .right{float:right;
    width:185px;
    vertical-align:middle;
    padding:12px 8px}
#bkmContent .bkmEntry .delImg{background-image:url();
    background-position:center;
    background-repeat:no-repeat;
    cursor:pointer;
    float:right;
    height:12px;
    width:13px}
#bkmContent .bkmEntry .delImg:hover{background-image:url()}
.bkmConfirmScreen{width:135px;
    float:right;
    font-size:1.1em;
    vertical-align:top}
.bkmConfirmScreen .deleteLabel{width:70px;
    color:#000;
    font-weight:700;
    float:right}
.bkmConfirmScreen .bkmDelConfirmNo{height:14px;
    width:30px;
    cursor:pointer;
    color:#09f;
    float:right;
    font-weight:400}
.bkmConfirmScreen .bkmDelConfirmNo:hover{text-decoration:underline}
.bkmConfirmScreen .bkmDelConfirmYes{height:14px;
    width:30px;
    cursor:pointer;
    color:#09f;
    float:right;
    font-weight:400}
.bkmConfirmScreen .bkmDelConfirmYes:hover{text-decoration:underline}
#accessRegion{position:absolute;
    text-align:left;
    top:0;
    width:100%}
#accessRegion a,#accessRegion a:hover,#accessRegion a:visited{position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden}
#accessRegion a:active,#accessRegion a:focus{background-color:#FAF8CC;
    border:1px solid #000;
    padding:5px;
    height:auto;
    left:auto;
    overflow:hidden;
    position:relative;
    top:20px;
    width:auto;
    z-index:11}
.clientRequest{position:absolute;
    top:300px;
    left:30px;
    z-index:1;
    width:1px;
    height:1px;
    visibility:hidden;
    overflow:hidden}
.promo{position:absolute;
    top:0;
    left:0;
    width:120px;
    height:90px;
    visibility:hidden}
IMG.preload{position:absolute;
    left:0;
    top:0;
    width:1px;
    height:1px;
    visibility:hidden}
.legacy HR{height:1px;
    color:#D0CFAF}
.mainlink{color:#369;
    text-decoration:underline;
    cursor:pointer}
DIV.IdModalWin{position:absolute;
    top:10%;
    z-index:1040;
    visibilty:hidden}
.inputBtnRow,.user-input-row{position:relative;
    line-height:1.4em;
    cursor:pointer}
@media all and (max-width:767px){.inputBtnRow,.user-input-row{margin:6px 0}
.inputBtnRow .inputBtnLbl label,.user-input-row .inputBtnLbl label{font-size:16px}
}
.inputBtn{position:absolute;
    left:0}
.inputBtnHorz{float:left}
.inputBtnLbl{padding:3px 0 0 20px;
    margin-left:2px;
    margin-right:10px}
.inputBtnLblHorz{padding:2px 12px 0 5px;
    float:left}
.user-input-row{white-space:nowrap}
.user-input-row input{vertical-align:top}
.user-input-row input+label{margin-left:10px;
    margin-right:10px;
    white-space:normal}
div.validationAlertPane{text-align:center;
    padding:50px}
.validationAlertText{font-size:.84em;
    font-weight:700;
    text-align:center;
    color:#333}
div.validationAlertCloseBtn{position:relative;
    margin:10px auto 0}
#waitRegion{z-index:1;
    min-height:200px;
    margin-top:50px}
#waitRegion .waitMsgBkg{font-size:1.5em;
    text-align:center}
#waitRegion .waitMsgBkg img{width:32px;
    height:32px}
#waitRegion .waitMsgBkg .waitMsgLayer{padding-left:10px;
    display:inline-block;
    position:absolute!ie}
#waitRegion .waitMsgBkg .waitSubMsg{font-size:1.07em}
@media (max-width:992px){#waitRegion{top:150px}
}
@media (max-width:768px){#waitRegion{max-width:75%;
    margin-left:13%;
    left:0}
}
#modalWrapper #modalContainer .waitMsgBkg{text-align:center}
#modalWrapper #modalContainer .waitMsgBkg img{width:32px;
    height:32px;
    margin:10px}
.RATransition{position:relative;
    padding:40px 0 0;
    color:#06f;
    font-weight:700;
    font-size:1.15em;
    height:183px;
    padding:20px;
    text-align:center;
    border:solid 1px #E0DEDE;
    border-radius:10px;
    -moz-border-radius:10px;
    -webkit-border-radius:10px;
    background-color:#F9F9F9;
    background-image:-moz-linear-gradient(center top,#ede9e9 0,#f9f9f9 90%,#f8f8f8 100%);
    background-image:-webkit-gradient(linear,center top,center bottom,from(#ede9e9),color-stop(.9,#f9f9f9),to(#f8f8f8));
    -pie-background:linear-gradient(#ede9e9,#f8f8f8)}
.RATransition img{display:block;
    position:relative;
    left:50%}
.serverDownLayer{position:relative;
    margin-top:20px;
    left:50px;
    color:#333;
    font-weight:700;
    font-size:1.38em}
div.errorTooltip{background:none repeat scroll 0 0 #242424!important;
    border-radius:2px!important;
    -moz-box-shadow:0 1px 0 #727272 inset;
    -webkit-box-shadow:0 1px 0 #727272 inset;
    box-shadow:0 1px 0 #727272 inset;
    margin-top:-7px;
    z-index:2}
#employerNameScreen #employerNameLabel{margin-top:27px;
    margin-bottom:3px}
#employerNameScreen #companyInput{width:55%;
    min-width:280px;
    max-width:400px;
    display:inline-block;
    vertical-align:top;
    margin-right:10px;
    margin-bottom:10px}
#employerNameScreen #employerNameForm{min-height:400px}
#employerNameScreen #w2Image{float:right;
    margin-top:-2px}
#employerNameScreen #skip,#employerNameScreen #continue{margin-left:12px}
@media (max-width:767px){#employerNameScreen #employerNameForm{min-height:80px}
#employerNameScreen #w2Image{display:none}
}
.bizcodeLabel{border-top:solid 1px;
    padding-top:5px}
.bizcodeItem{margin-bottom:5px}
.bizcodeItem input{vertical-align:middle;
    margin-right:5px;
    margin-top:-3px}
.bizcodeInstruct{margin-bottom:10px}
#bzGO{margin-left:15px}
#BCLSearchID{width:80%}
.bizCodeSrch{position:relative;
    width:100%;
    height:100%;
    overflow:hidden}
.bizCodeSrchBox{margin:5px 10px 5px 0;
    white-space:nowrap}
.bizCodeSrchBody{margin:6px 20px}
.bizCodeBtnWrapper{width:20%;
    display:inline}
#startupMsg{position:absolute;
    z-index:1000;
    width:500px;
    font-weight:700;
    color:#333;
    padding:20px;
    border:solid 1px #E0DEDE;
    border-radius:3px;
    -moz-border-radius:3px;
    -webkit-border-radius:3px;
    text-align:center;
    background-color:#F9F9F9}
#startupTxt{font-size:1.2em;
    margin-left:10px;
    line-height:31px}
#view_tablet_link{display:none}
DIV.HelpPopup{position:absolute;
    width:472px;
    height:463px;
    top:0;
    left:0;
    z-index:0}
DIV.HelpViewerLinks{position:absolute;
    top:10px;
    right:18px;
    left:18px}
DIV.HelpClose{position:absolute;
    top:-10px;
    left:415px;
    z-index:302}
DIV.HelpBack{position:absolute;
    top:17px;
    left:10px}
DIV.HelpViewer{position:absolute;
    top:27px;
    left:21px;
    width:414px;
    height:370px;
    border:solid 1px #ababab;
    background-color:#fff;
    overflow:hidden;
    overflow:-moz-scrollbars-none;
    overflow:-moz-scrollbars-vertical;
    overflow-x:hidden;
    overflow-y:auto;
    -webkit-overflow-scrolling:touch}
#HELPPOPUP{min-height:370px;
    height:auto!important}
DIV.HelpPopupContent{text-align:left;
    padding-left:7px;
    background-color:#fff;
    width:407px;
    overflow:hidden}
DIV.HelpLinks{position:absolute;
    width:327px;
    height:30px;
    top:320px;
    left:30px;
    text-align:right}
DIV.HelpFooterLinks{position:absolute;
    top:409px;
    left:0;
    height:35px;
    width:100%;
    text-align:center;
    color:#666;
    font-size:14px}
DIV.HelpPrint{float:left;
    padding-left:28px;
    padding-top:3px;
    font-size:11px;
    cursor:hand;
    cursor:pointer;
    }
DIV.HelpMore{float:right;
    padding-right:35px}
DIV.HelpBackMore{float:right;
    padding-right:27px}
DIV.HelpMorePopup{position:absolute;
    width:280px;
    height:463px;
    top:0;
    left:450px;
    z-index:-1;
    visibility:hidden}
DIV.HelpMoreViewer{position:absolute;
    top:27px;
    left:13px;
    width:250px;
    height:370px;
    overflow:hidden}
DIV.HelpMorePopupContent{text-align:left;
    padding-left:7px;
    background-color:#fff;
    height:270px;
    width:250px;
    overflow:-moz-scrollbars-none;
    overflow:-moz-scrollbars-vertical;
    overflow-y:auto;
    overflow-x:hidden}
.legacy TABLE.mcTable{table-layout:auto;
    width:100%}
.legacy TABLE.mcTable button{padding:0 10px}
.legacy TABLE.mcTable .mcHeading td{color:#FFF;
    background:#A0A0A0;
    white-space:normal;
    text-transform:uppercase;
    font-size:13px}
@media all and (max-width:568px){.legacy TABLE.mcTable .mcHeading{display:none}
.legacy TABLE.mcTable .mcRow{display:block;
    border-top:solid 2px #e5e5e5}
.legacy TABLE.mcTable .mcRow td{border:none;
    display:block;
    text-align:left}
.legacy TABLE.mcTable .mcRow td[data-header-val]:before{content:attr(data-header-val) ":";
    display:inline-block;
    text-transform:uppercase;
    padding-right:5px;
    width:12em;
    font-weight:600}
.legacy TABLE.mcTable .mcRow td.mcTotalCol:first-of-type{color:#FFF;
    background:#A0A0A0;
    text-transform:uppercase}
.legacy TABLE.mcTable .mcRow td.mcTotalCol:first-of-type:before{content:"";
    width:0}
.legacy TABLE.mcTable .mcAddCol,.legacy TABLE.mcTable .mcDeleteCol{font-size:1.2em;
    text-align:center!important}
}
.legacy TR.mcRow{background:#fff;
    border-top:solid 1px #e5e5e5}
.legacy td.mcDeleteCol{white-space:nowrap}
.legacy TD.mcTotalCol,.legacy .grid TD.mcTotalCol{border-top:2px solid #ccc;
    border-bottom:2px solid #ccc;
    font-weight:600;
    color:#393a3d}
#auditRiskMeter{height:300px}
#auditRiskMeter #armContainer{width:100%;
    height:100px;
    margin-top:20px}
#auditRiskMeter #armHeadline{height:80px;
    margin-bottom:-30px}
@media all and (max-width:320px){#auditRiskMeter #armHeadline{height:auto}
}
#auditRiskMeter #armHeadline h2{color:#0077c5;
    margin:0;
    padding:0;
    font-size:30px;
    margin-left:2%;
    padding-top:50px;
    opacity:.1}
@media (max-width:360px){#auditRiskMeter #armHeadline h2{font-size:28px}
}
#auditRiskMeter #armMeterContainer{border-right:solid 1px #898989;
    height:200px}
@media all and (max-width:320px){#auditRiskMeter #armMeterContainer{height:auto}
}
#auditRiskMeter #armMeter{width:90%;
    height:45px;
    border:solid 1px #AAA;
    border-radius:8px;
    margin:20px;
    margin-top:60px;
    margin-bottom:10px}
#auditRiskMeter #armBar{width:100%;
    border:solid 3px #FFF;
    border-radius:10px;
    height:100%;
    background:-moz-linear-gradient(left,#5fc454 0,#fed631 50%,#f90 100%);
    background:-webkit-gradient(linear,left top,right top,color-stop(0,#5fc454),color-stop(50%,#fed631),color-stop(100%,#f90));
    background:-webkit-linear-gradient(left,#5fc454 0,#fed631 50%,#f90 100%);
    background:-o-linear-gradient(left,#5fc454 0,#fed631 50%,#f90 100%);
    background:-ms-linear-gradient(left,#5fc454 0,#fed631 50%,#f90 100%);
    background:linear-gradient(to right,#5fc454 0,#fed631 50%,#f90 100%)}
#auditRiskMeter #armMask{float:right;
    border-radius:0 8px 8px 0;
    width:0;
    height:40px;
    margin-top:-43px;
    background-color:#FFF}
#auditRiskMeter #armPointer{width:0;
    height:0;
    border-left:12px solid transparent;
    border-right:12px solid transparent;
    border-top:30px solid #666;
    overflow:visible;
    margin-left:0;
    margin-right:-12px;
    margin-top:-130px;
    opacity:0;
    float:right}
#auditRiskMeter #armLabel{margin-left:-40px;
    text-align:center;
    width:80px;
    font-size:15px;
    font-weight:400;
    color:#898989;
    margin-top:-40px;
    opacity:0}
#auditRiskMeter #armDescription{width:90%;
    margin:0 auto;
    font-size:16px;
    color:#898989}
#auditRiskMeter #armDescription .armDescriptionLabel{width:33%;
    height:16px;
    float:left}
@media all and (max-width:320px){#auditRiskMeter #armDescription .armDescriptionLabel{height:auto}
}
#auditRiskMeter #armVeryLow{text-align:left}
#auditRiskMeter #armMedium{text-align:center}
#auditRiskMeter #armHigh{text-align:right}
#auditRiskMeter #armExplanation{width:100%;
    height:50px;
    font-size:17px;
    color:#898989;
    padding:5%;
    padding-top:20px;
    margin-bottom:20px;
    display:block;
    clear:both}
@media all and (max-width:320px){#auditRiskMeter #armExplanation{height:auto}
}
#auditRiskMeter #armExplanation a{font-size:14px;
    margin-left:10px}
#auditRiskMeter #armDetails{width:90%;
    padding-left:30px;
    font-size:16px;
    color:#898989;
    display:none;
    margin-top:120px;
    clear:both}
#auditRiskMeter #armBadgeContainer{text-align:center;
    padding-top:10px}
#auditRiskMeter #armBadgeContainer p{font-size:13px;
    text-align:left;
    margin:5px}
#auditRiskMeter #armBadgeContainer a{font-size:13px}
#auditRiskMeter #armPonButton{border:none;
    background:0 0;
    color:#037c8f;
    text-decoration:none;
    font-size:14px;
    margin:0;
    padding:0}
#auditRiskMeter #armPonButton:hover{text-decoration:underline;
    color:#2A6496}
@media (max-width:767px){#auditRiskMeter #armMeterContainer{border-right:none}
}
@media (max-width:768px){#modalWrapper{left:0!important}
#modalContainer{left:0!important}
}
#fuegoPlayerContainer button#ExpertReviewScheduler-Collection-Text{font-size:14px;
    color:#FFF;
    background:#34bfff;
    -webkit-box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    -moz-box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    box-shadow:0 1px 1px 0 rgba(0,0,0,.75);
    margin-bottom:5px;
    border-radius:2px;
    font-weight:400;
    cursor:pointer;
    text-decoration:none;
    display:inline-block;
    padding:6px 12px;
    line-height:1.42857143;
    text-align:center;
    white-space:nowrap;
    vertical-align:middle;
    touch-action:manipulation;
    user-select:none;
    border:1px solid transparent}
#fuegoPlayerContainer button#ExpertReviewScheduler-Collection-Text:hover{background:#7fd6ff}

      .jstree-default li,.jstree-default ins{background-image:url();
    background-repeat:no-repeat;
    background-color:transparent}
.jstree-default li{background-position:-90px 0;
    background-repeat:repeat-y}
.jstree-default li.jstree-last{background:0 0}
.jstree-default .jstree-open>ins{background-position:-72px 0}
.jstree-default .jstree-closed>ins{background-position:-54px 0}
.jstree-default .jstree-leaf>ins{background-position:-36px 0}
.jstree-default .jstree-hovered{background:#e7f4f9;
    border:1px solid #d8f0fa;
    padding:0 2px 0 1px}
.jstree-default .jstree-clicked{background:#beebff;
    border:1px solid #99defd;
    padding:0 2px 0 1px}
.jstree-default a .jstree-icon{background-position:-56px -19px}
.jstree-default a.jstree-loading .jstree-icon{background:url() center center no-repeat!important}
.jstree-default.jstree-focused{background:#ffe}
.jstree-default .jstree-no-dots li,.jstree-default .jstree-no-dots .jstree-leaf>ins{background:0 0}
.jstree-default .jstree-no-dots .jstree-open>ins{background-position:-18px 0}
.jstree-default .jstree-no-dots .jstree-closed>ins{background-position:0 0}
.jstree-default .jstree-no-icons a .jstree-icon{display:none}
.jstree-default .jstree-search{font-style:italic}
.jstree-default .jstree-no-icons .jstree-checkbox{display:inline-block}
.jstree-default .jstree-no-checkboxes .jstree-checkbox{display:none!important}
.jstree-default .jstree-checked>a>.jstree-checkbox{background-position:-38px -19px}
.jstree-default .jstree-unchecked>a>.jstree-checkbox{background-position:-2px -19px}
.jstree-default .jstree-undetermined>a>.jstree-checkbox{background-position:-20px -19px}
.jstree-default .jstree-checked>a>.jstree-checkbox:hover{background-position:-38px -37px}
.jstree-default .jstree-unchecked>a>.jstree-checkbox:hover{background-position:-2px -37px}
.jstree-default .jstree-undetermined>a>.jstree-checkbox:hover{background-position:-20px -37px}
#vakata-dragged.jstree-default ins{background:transparent!important}
#vakata-dragged.jstree-default .jstree-ok{background:url() -2px -53px no-repeat!important}
#vakata-dragged.jstree-default .jstree-invalid{background:url() -18px -53px no-repeat!important}
#jstree-marker.jstree-default{background:url() -41px -57px no-repeat!important;
    text-indent:-100px}
.jstree-default a.jstree-search{color:#0ff}
.jstree-default .jstree-locked a{color:silver;
    cursor:default}
#vakata-contextmenu.jstree-default-context,#vakata-contextmenu.jstree-default-context li ul{background:#f0f0f0;
    border:1px solid #979797;
    -moz-box-shadow:1px 1px 2px #999;
    -webkit-box-shadow:1px 1px 2px #999;
    box-shadow:1px 1px 2px #999}
#vakata-contextmenu.jstree-default-context a{color:#000}
#vakata-contextmenu.jstree-default-context a:hover,#vakata-contextmenu.jstree-default-context .vakata-hover>a{padding:0 5px;
    background:#e8eff7;
    border:1px solid #aecff7;
    color:#000;
    -moz-border-radius:2px;
    -webkit-border-radius:2px;
    border-radius:2px}
#vakata-contextmenu.jstree-default-context li.jstree-contextmenu-disabled a,#vakata-contextmenu.jstree-default-context li.jstree-contextmenu-disabled a:hover{color:silver;
    background:0 0;
    border:0;
    padding:1px 4px}
#vakata-contextmenu.jstree-default-context li.vakata-separator{background:#fff;
    border-top:1px solid #e0e0e0;
    margin:0}
#vakata-contextmenu.jstree-default-context li ul{margin-left:-4px}

      .debugMain{color:#cece00}
.debugDesc{color:#f5f5f5}
a.dbgLnk{font-size:11px;
    font-weight:700;
    color:#a2d9f9;
    text-decoration:none;
    cursor:pointer}
a.dbgLnk hover{text-decoration:underline}
#debug_content .btn{margin-bottom:5px;
    margin-right:5px}
DIV.DebugLayer{border-radius:6px;
    -moz-border-radius:6px;
    -webkit-border-radius:6px;
    background-color:#999;
    background-color:rgba(100,100,100,.5);
    -moz-box-shadow:0 0 6px #999;
    -webkit-box-shadow:0 0 6px #999;
    box-shadow:0 0 6px #999;
    padding:8px;
    position:absolute;
    top:50px;
    left:20px;
    width:500px;
    z-index:599;
    text-align:left;
    overflow:hidden}
DIV.innerDebugLayer{background:#444}
DIV.dbgClose{-moz-border-radius:3px;
    border-radius:3px;
    -moz-box-shadow:0 0 4px #999;
    -webkit-box-shadow:0 0 4px #999;
    box-shadow:0 0 4px #999;
    border:1px solid #aaa;
    color:#111;
    cursor:pointer;
    float:right;
    font:700 9px Arial,sans-serif;
    padding:2px 5px;
    background-color:rgba(250,250,250,.7);
    -webkit-transition:background-color .4s ease-out;
    margin:-24px 5px}
DIV.dbgClose:hover{-moz-border-radius:3px;
    border-radius:3px;
    -moz-box-shadow:0 0 4px #999;
    -webkit-box-shadow:0 0 4px #999;
    box-shadow:0 0 4px #999;
    border:1px solid #aaa;
    color:#fff;
    cursor:pointer;
    float:right;
    font-size:9px;
    font-size-adjust:none;
    padding:2px 5px;
    background-color:rgba(100,100,100,.7);
    text-decoration:none}
DIV.dbgTtlLayer{padding:6px;
    cursor:move;
    background:#e2e2e2;
    background:-moz-linear-gradient(top,#e2e2e2 0,#dbdbdb 50%,#d1d1d1 51%,#fefefe 100%);
    background:-webkit-gradient(linear,left top,left bottom,color-stop(0,#e2e2e2),color-stop(50%,#dbdbdb),color-stop(51%,#d1d1d1),color-stop(100%,#fefefe));
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=0)}
div.DebugTraceLayer{position:absolute;
    background:#78899A;
    padding:2px;
    z-index:600;
    border:solid 1px #333;
    border-right:solid 2px #333;
    border-bottom:solid 2px #333}
span.DebugTraceClose{position:absolute;
    top:5px;
    right:4px;
    font:700 10px Arial,sans-serif;
    float:right;
    border:solid 1px #000;
    color:#000;
    background-color:#B0E0E6;
    cursor:pointer;
    text-decoration:none;
    padding:2px}
span.DebugTraceResizeHandle{border:5px solid #ccc;
    border-left-color:#000;
    border-top-color:#000;
    cursor:pointer;
    color:#ccc;
    display:block;
    height:0;
    width:0;
    overflow:hidden}
div.DebugTraceToolsLayer{position:absolute;
    font-size:12px;
    line-height:18px;
    bottom:5px;
    left:4px}
span.DebugTraceTool{margin:0 2px 0 0;
    padding:0 5px;
    font:700 10px Arial,sans-serif;
    float:left;
    border:solid 1px #000;
    color:#000;
    background-color:#c7d285;
    cursor:pointer;
    text-decoration:none}
h3.DebugTraceHeader{background:#2F4F4F;
    color:#FFF;
    cursor:move;
    margin:0 0 2px;
    padding:5px 10px;
    line-height:15px;
    font:700 13px Arial,sans-serif}
pre.DebugTraceView{border:1px solid #ccc;
    background:#B0C4DE;
    color:#000;
    font-size:1.2em;
    margin:0;
    padding:0 3px;
    position:absolute;
    overflow:auto}
.coda-slider-wrapper{padding:0}
.coda-slider{background:0 0}
.coda-slider-no-js .coda-slider{height:200px;
    overflow:auto!important;
    padding-right:20px}
.coda-slider,.coda-slider .panel{width:500px}
.coda-slider-wrapper.arrows .coda-slider,.coda-slider-wrapper.arrows .coda-slider .panel{width:500px}
.coda-slider-wrapper.arrows .coda-slider{margin:0 10px}
.coda-nav-left a,.coda-nav-right a{background:#000;
    color:#fff;
    padding:5px;
    width:100px}
.coda-nav{padding-top:8px;
    border-bottom:solid 1px #555;
    cursor:move;
    background:#e2e2e2;
    background:-moz-linear-gradient(top,#e2e2e2 0,#dbdbdb 50%,#d1d1d1 51%,#fefefe 100%);
    background:-webkit-gradient(linear,left top,left bottom,color-stop(0,#e2e2e2),color-stop(50%,#dbdbdb),color-stop(51%,#d1d1d1),color-stop(100%,#fefefe))}
.coda-nav ul li a{border-radius:5px 5px 0 0;
    -moz-border-radius:5px 5px 0 0;
    -webkit-border-radius:5px 5px 0 0}
.coda-nav ul li a.current{background:#39c}
.coda-slider .panel-wrapper{padding:10px;
    min-height:300px}
.coda-slider p.loading{padding:20px;
    text-align:center}
.coda-nav ul{clear:both;
    display:block;
    margin:auto;
    overflow:hidden}
.coda-nav ul li{display:inline}
.coda-nav ul li a{background:#333;
    color:#f5f5f5;
    display:block;
    float:left;
    margin-right:2px;
    padding:3px 6px;
    text-decoration:none}
.coda-slider-wrapper{clear:both;
    overflow:auto}
.coda-slider{float:left;
    overflow:hidden;
    position:relative}
.coda-slider .panel{display:block;
    float:left}
.coda-slider .panel-container{position:relative}
.coda-nav-left,.coda-nav-right{float:left}
.coda-nav-left a,.coda-nav-right a{display:block;
    text-align:center;
    text-decoration:none}
div.panelContainer{width:97%;
    height:300px;
    overflow:auto}
#debug_console_wrapper{z-index:99999999}
#debug_console_wrapper pre{background-color:#999}
#debug_console_toggle_button{width:auto}
.debug_console_menu_toggle_button_on,.debug_console_menu_toggle_button_off{padding:3px 8px;
    border-radius:6px;
    cursor:pointer}

      @font-face{font-family:'QuickType Pi';
    /* src:url('../fonts/QuickType/QuickType Pi.eot?#iefix') format('embedded-opentype'),url('../fonts/QuickType/QuickType Pi.svg#QuickTypePi') format('svg'),url('../fonts/QuickType/QuickType Pi.ttf') format('truetype') */
}
.borderedFormLayer{background-color:#F0F0EE;
    -webkit-box-shadow:0 0 5px #6C6C6C;
    -moz-box-shadow:0 0 5px #6C6C6C;
    box-shadow:0 0 5px #6C6C6C;
    padding:5px}
.formContentPanel{margin:0 auto;
    max-width:800px;
    background-color:#FFF;
    position:relative;
    padding:15px;
    border:1px solid #969696;
    -webkit-box-shadow:0 0 7px #6F6F6F;
    -moz-box-shadow:0 0 7px #6F6F6F;
    box-shadow:0 0 7px #6F6F6F;
    border-radius:4px;
    -moz-border-radius:4px}
#modalForm{overflow-x:scroll}
.html-forms-mode INPUT.formTextBox:not([type=radio]):not([type=checkbox]),body.native-app .html-forms-mode INPUT.formTextBox:not([type=radio]):not([type=checkbox]){color:#0f67a1;
    line-height:19px;
    padding:0 2px;
    margin:0;
    font-size:14px!important}
@media (-webkit-min-device-pixel-ratio:0){.html-forms-mode input[type=radio],body.native-app .html-forms-mode input[type=radio],.html-forms-mode input[type=checkbox],body.native-app .html-forms-mode input[type=checkbox]{width:auto!important;
    height:auto!important;
    visibility:inherit;
    position:absolute!important;
    margin:initial!important;
    -webkit-appearance:radio;
    background:none!important}
.html-forms-mode input[type=checkbox],body.native-app .html-forms-mode input[type=checkbox]{-webkit-appearance:checkbox}
.html-forms-mode input+label,body.native-app .html-forms-mode input+label,.html-forms-mode input+span,body.native-app .html-forms-mode input+span{margin:auto!important;
    padding:inherit;
    max-width:inherit;
    vertical-align:inherit}
}
.formButton{font-weight:700;
    font-size:.8em}
.formButton:disabled{font-weight:700;
    font-size:.8em;
    color:gray}
.formDropDown{min-width:44px;
    font-size:.85em;
    border-radius:3px;
    -moz-border-radius:3px;
    padding:0}
.formLoaderIcon{position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background-image:url();
    background-repeat:no-repeat;
    background-position:50% 40px;
    background-color:#FFF;
    opacity:.9;
    z-index:1001}
INPUT[type=radio].editErr,INPUT[type=checkbox].editErr{outline:1px solid #FF6161}
.debugModeContainer{background:#e7e7e7 no-repeat;
    margin:0;
    padding:0;
    font-family:Calibri,serif;
    position:absolute;
    z-index:100;
    width:100%;
    min-height:1000px;
    top:0;
    text-align:left}
.debugModeHeader{padding-left:40px;
    padding-top:15px;
    font-size:24px;
    color:#fff;
    height:80px;
    background-color:#00A6E2}
.debugModeButtonRow{border:1px solid #889EB9;
    -webkit-box-shadow:0 0 2px #889EB9;
    -moz-box-shadow:0 0 2px #889EB9;
    box-shadow:0 0 2px #889EB9;
    background-color:#E8ECEE;
    margin-bottom:10px;
    padding:5px;
    margin-left:2px;
    margin-right:2px}
.debugModeButton{-moz-box-shadow:inset 0 1px 0 0 #bbdaf7;
    -webkit-box-shadow:inset 0 1px 0 0 #bbdaf7;
    box-shadow:inset 0 1px 0 0 #bbdaf7;
    background:-webkit-gradient(linear,left top,left bottom,color-stop(.05,#79bbff),color-stop(1,#378de5));
    background:-moz-linear-gradient(center top,#79bbff 5%,#378de5 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#79bbff', endColorstr='#378de5');
    background-color:#79bbff;
    -moz-border-radius:4px;
    -webkit-border-radius:4px;
    border-radius:4px;
    border:1px solid #84bbf3;
    display:inline-block;
    color:#fff;
    font-size:11px;
    font-weight:700;
    margin-left:3px;
    padding:5px 10px;
    text-decoration:none;
    text-shadow:1px 1px 0 #528ecc}
.debugModeButton:hover{background:-webkit-gradient(linear,left top,left bottom,color-stop(.05,#378de5),color-stop(1,#79bbff));
    background:-moz-linear-gradient(center top,#378de5 5%,#79bbff 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#378de5', endColorstr='#79bbff');
    background-color:#378de5}
.debugModeButton:active{background:#6A98B8}

      #helpPanel{width:0;
    height:100%;
    display:block;
    position:fixed;
    z-index:11;
    background-color:#fff;
    top:0;
    right:0;
    backface-visibility:hidden;
    -webkit-overflow-scrolling:touch!important;
    overflow:hidden;
    border-left:solid 1px #c8c8c8;
    max-width:945px}
#helpPanel iframe{width:100%;
    height:99%;
    border:none}
.helpPanelBlanket{background:rgba(255,255,255,.6);
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:9998;
    -webkit-transition:opacity .25s ease-out;
    transition:opacity .25s ease-out;
    visibility:hidden;
    opacity:0}
.helpPanelBlanket.open{visibility:visible;
    opacity:1;
    -webkit-transition:opacity .25s ease-out;
    transition:opacity .25s ease-out}
.elevateHelpPanelZIndex{z-index:9999!important}
.helpPanelMask{background-color:#fff;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    right:0;
    z-index:50}
.isMobile #helpPanel{position:absolute;
    border:solid 1px #c8c8c8;
    top:55px;
    -moz-box-shadow:#c8c8c8;
    -webkit-box-shadow:#c8c8c8;
    box-shadow:#c8c8c8}
@media all and (max-width:767px){#helpPanel{width:0;
    height:100%;
    display:block;
    position:absolute;
    top:30px;
    left:0;
    z-index:9}
.isMobile #helpPanel{top:86px}
}

      #help_search_wrapper{position:relative;
    float:right;
    margin:-16px 0 -16px 20px;
    height:62px;
    color:#fff;
    font-size:14px;
    font-weight:100}
#help_search_wrapper button{background:0 0;
    border:none}
#help_search_wrapper button:focus{outline:0}
#help_search_wrapper .helpHalfButton{padding:15px 18px 15px 18px;
    font-size:14px;
    font-family:Avenir,Helvetica,Arial,san-serif}
@media all and (min-width:768px) and (max-width:991px){#help_search_wrapper .helpHalfButton{padding:12px 25px 0 30px}
}
@media all and (max-width:767px){#help_search_wrapper .helpHalfButton{display:none}
}
#help_search_wrapper .helpHalfButton:hover>span,#help_search_wrapper .helpHalfButton:active>span,#help_search_wrapper .helpHalfButton:focus>span{text-decoration:underline}
#help_search_wrapper .helpHalfButton:hover>.icon,#help_search_wrapper .helpHalfButton:active>.icon,#help_search_wrapper .helpHalfButton:focus>.icon{color:#fff;
    text-decoration:none}
#help_search_wrapper .minimize-btn{background-color:rgba(25,55,74,.2);
    -webkit-transform:scale(0);
    -ms-transform:scale(0);
    transform:scale(0);
    padding:0;
    transition:all .3s;
    width:0}
@media all and (max-width:767px){#help_search_wrapper .minimize-btn{float:right}
}
#help_search_wrapper .minimize-btn:hover>.icon,#help_search_wrapper .minimize-btn:active>.icon,#help_search_wrapper .minimize-btn:focus>.icon{color:#fff;
    text-decoration:none}
#help_search_wrapper .minimize-btn.open{margin-left:2px;
    -webkit-transform:scale(1);
    -ms-transform:scale(1);
    transform:scale(1);
    padding:15px 18px;
    width:auto}
@media all and (max-width:991px){#help_search_wrapper .minimize-btn.open{height:49px;
    padding:12px 18px}
}
@media all and (max-width:767px){#help_search_wrapper .minimize-btn.open{height:48px;
    padding:0 18px;
    margin:-10px 0 -10px 10px}
}
@media all and (max-width:1200px){#help_search_wrapper{margin:-16px 0 -16px 0}
}
@media all and (min-width:768px) and (max-width:991px){#help_search_wrapper{height:49px}
}
#help_search_wrapper .icon{color:#a1c7dd;
    font-size:23px;
    position:relative;
    top:4px;
    width:25px;
    margin-right:2px}
#help_search_wrapper .divider{display:inline-block;
    height:34px;
    width:1px;
    background:#5c9ec3;
    position:absolute;
    top:25%}
@media all and (max-width:767px){#help_search_wrapper .divider{display:none}
}
#help_search_wrapper.isMobile{padding:16px 0 16px;
    background-color:transparent!important}
@media all and (max-width:991px){#help_search_wrapper.isMobile{padding:13px 0 0}
}
@media all and (max-width:767px){#help_search_wrapper.isMobile{padding:0}
}
#help_search_wrapper.isMobile .helpHalfButton,#help_search_wrapper.isMobile .divider{display:none}
#help_search_wrapper.isMobile #helpSearch{display:inline-block}
@media all and (max-width:767px){#help_search_wrapper.isMobile #helpSearch{display:none}
}
#help_search_wrapper.notified{margin-left:0}
#help_search_wrapper.notified #helpNotification{display:inline-block;
    float:left}
@media all and (max-width:767px){#help_search_wrapper.notified #helpNotification{float:none;
    right:35px;
    position:absolute;
    margin:0 5px 0 1px}
}
@media all and (min-width:991px){#help_search_wrapper.notified #helpNotification{margin-top:18px}
}
@media all and (min-width:767px){#help_search_wrapper.notified #helpNotification{margin-left:20px;
    margin-top:16px}
}
.helpBtnWrapper{background-color:rgba(25,55,74,.2);
    height:62px;
    float:left}
@media all and (min-width:768px) and (max-width:991px){.helpBtnWrapper{height:49px}
}
@media all and (max-width:767px){.helpBtnWrapper{display:none}
}
#helpNotification{display:none;
    position:relative;
    margin:0 15px 0 1px;
    float:left}
#helpNotification a.bubble{display:block;
    position:relative;
    width:32px;
    height:30px;
    background:url() no-repeat center center}
#helpNotification .badge{display:none;
    border-radius:20px;
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    padding:4px;
    position:absolute;
    top:-5px;
    right:-5px;
    width:19px;
    background-color:#e44b45;
    font-size:10px}
#helpNotification .dialog{display:none;
    z-index:200;
    position:absolute;
    top:55px;
    left:-115px;
    width:240px;
    border-color:#53565a;
    background-color:#53565a;
    padding:15px 20px;
    color:#fff;
    font-size:14px;
    text-align:center;
    -moz-box-shadow:0 3px 15px 3px #999;
    -webkit-box-shadow:0 3px 15px 3px #999;
    box-shadow:0 3px 15px 3px #999;
    border-radius:5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px}
#helpNotification .dialog .arrow{position:absolute;
    top:-10px;
    left:50%;
    width:0;
    height:0;
    border-left:10px solid transparent;
    border-right:10px solid transparent;
    border-bottom:10px solid #53565a}
#helpNotification .dialog a{text-decoration:underline;
    color:#fff;
    font-size:14px}
#helpSearch{display:none;
    position:relative;
    float:right;
    width:425px}
@media all and (max-width:1199px){#helpSearch{width:345px}
}
@media all and (max-width:991px){#helpSearch{width:425px}
}
#helpSearch span.twitter-typeahead{width:100%}
#helpSearch span.twitter-typeahead input{float:none;
    width:100%}
#helpSearch span.twitter-typeahead input.tt-hint{color:#a9a9a9}
#helpSearch span.twitter-typeahead input::-webkit-input-placeholder{color:#555}
#helpSearch span.twitter-typeahead input:-moz-placeholder{color:#555}
#helpSearch span.twitter-typeahead input::-moz-placeholder{color:#555}
#helpSearch span.twitter-typeahead input:-ms-input-placeholder{color:#555}
#helpSearch span.twitter-typeahead .tt-dropdown-menu{position:absolute;
    background-color:#fff;
    background-color:rgba(255,255,255,.95);
    border:solid 1px #c8c8c8;
    left:-6px!important;
    width:287px;
    -moz-box-shadow:0 3px 15px 3px #999;
    -webkit-box-shadow:0 3px 15px 3px #999;
    box-shadow:0 3px 15px 3px #999;
    margin-top:3px}
#helpSearch span.twitter-typeahead .tt-dropdown-menu .tt-suggestion{padding:0}
#helpSearch span.twitter-typeahead .tt-dropdown-menu .tt-suggestion p{font-size:14px;
    line-height:32px;
    color:#383838;
    margin:0 12px}
#helpSearch span.twitter-typeahead .tt-dropdown-menu .tt-suggestion.tt-is-under-cursor{background-color:#d3e9fa;
    background-image:none}
#helpSearch input{-webkit-border-radius:2px;
    -webkit-background-clip:padding-box;
    -moz-border-radius:2px;
    -moz-background-clip:padding;
    border-radius:2px;
    background-clip:padding-box;
    width:100%;
    border:none;
    height:30px;
    transition-duration:.3s}
#helpSearch #chatAskButton{position:absolute;
    top:3px;
    right:5px;
    width:25px;
    color:#999;
    text-decoration:none;
    line-height:25px;
    font-size:25px}
#helpSearch #chatQuestion{width:100%;
    background-color:#fff!important}
#helpSearch.on #chatQuestion{-webkit-box-shadow:0 0 0 3px #63c45b;
    -moz-box-shadow:0 0 0 3px #63c45b;
    box-shadow:0 0 0 3px #63c45b;
    outline:0;
    border:none!important}
a#helpSearchMobile{display:none;
    float:right;
    width:30px;
    height:30px;
    background:url() no-repeat center center;
    background-size:22px;
    font-size:30px;
    font-weight:700;
    color:#fff;
    text-indent:100%;
    white-space:nowrap;
    overflow:hidden;
    -webkit-transition:background 200ms;
    -moz-transition:background 200ms;
    -ms-transition:background 200ms;
    -o-transition:background 200ms;
    transition:background 200ms}
a#helpSearchMobile:hover{text-decoration:none}
a#helpSearchMobile.open{background:url() no-repeat center center;
    background-size:22px}
#helpSuggest{display:none;
    position:absolute;
    background-color:#fff;
    background-color:rgba(255,255,255,.95);
    border:solid 1px #c8c8c8;
    top:58px;
    right:16px;
    width:287px;
    -moz-box-shadow:0 3px 15px 3px #999;
    -webkit-box-shadow:0 3px 15px 3px #999;
    box-shadow:0 3px 15px 3px #999}
#helpSuggest p{margin:10px 12px 5px;
    text-transform:uppercase;
    color:#858585;
    font-size:14px}
#helpSuggest ul{margin:5px 0;
    padding:0}
#helpSuggest ul li{margin:0;
    padding:0;
    list-style-type:none}
#helpSuggest ul li a{display:block;
    font-size:14px;
    line-height:32px;
    padding:0 12px;
    margin:0;
    color:#000}
#helpSuggest ul li a:hover,#helpSuggest ul li a.on{color:#000;
    text-decoration:none;
    background-color:#d3e9fa}
body.sku_512 #helpSuggest,body.sku_4 #helpSuggest,body.sku_512 .tt-dropdown-menu,body.sku_4 .tt-dropdown-menu{border-color:#c8c8c8!important}
body.sku_2 #helpSuggest,body.sku_2 .tt-dropdown-menu{border-color:#c8c8c8!important}
body.sku_8 #helpSuggest,body.sku_8 .tt-dropdown-menu{border-color:#c8c8c8!important}
body.sku_32 #helpSuggest,body.sku_64 #helpSuggest,body.sku_4096 #helpSuggest,body.sku_32 .tt-dropdown-menu,body.sku_64 .tt-dropdown-menu,body.sku_4096 .tt-dropdown-menu{border-color:#c8c8c8!important}

a#helpSearchMobile{display:inline-block}
#helpSearch{display:none;
    float:right;
    margin-top:15px;
    padding:5px;
    width:100%;
    height:auto}
#helpSearch span.twitter-typeahead{width:100%}
#helpSearch span.twitter-typeahead .tt-dropdown-menu{border:none;
    left:0;
    width:100%;
    -moz-box-shadow:none;
    -webkit-box-shadow:none;
    box-shadow:none;
    margin-top:3px}
#helpSearch span.twitter-typeahead .tt-dropdown-menu .tt-suggestions{border-top:solid 1px #ccc}
#helpSearch span.twitter-typeahead .tt-dropdown-menu .tt-suggestion p{font-size:26px;
    line-height:42px;
    border-bottom:solid 1px #ccc}
#helpSearch input{font-size:16px}
#helpSearch a#chatAskButton{top:8px;
    right:7px}
#helpSuggest{border:none;
    left:0;
    top:121px;
    width:100%}
#helpSuggest p,#helpSuggest ul li a{font-size:26px;
    line-height:42px}

#interceptor span{margin-top:38px;
    font-size:16px;
    color:#fff;
    display:inline-block;
    white-space:nowrap;
    -webkit-transform:rotate(-90deg);
    -moz-transform:rotate(-90deg);
    -o-transform:rotate(-90deg);
    transform:rotate(-90deg);
    filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3)}
#interceptor span.ie9{filter:none;
    -ms-transform:rotate(-90deg)}
#interceptor span:before{content:"";
    float:left;
    margin-top:100%}
#interceptor.livehelp{height:134px}
#interceptor.SOS{height:100px;
    background-image:none}
#interceptor.SOS span{margin-top:10px}
#interceptor:hover{background-color:#73D3FF}
#interceptor:active{background-color:#167FAF}
#interceptor.inactive{background-color:#A1A1A1}
#interceptor.inactive:hover{background-color:#C1C1C1}
#interceptor.inactive:active{background-color:#717171}

      .legacy body .modal-open{overflow:hidden}
.legacy #ponModalWrap .tto-draggable:hover{cursor:move}
.legacy #ponModalWrap .row{padding:0}
.legacy #ponHeader{height:auto;
    min-height:90px;
    padding-left:23px;
    border-bottom:1px solid #e5e5e5}
.legacy #ponHeadline{padding-left:10px;
    padding-top:30px}
.legacy #ponBack{font-family:Avenir,Helvetica,Arial,sans-serif;
    display:none;
    position:absolute;
    z-index:20;
    height:30px;
    padding:0;
    padding-left:7px;
    margin:13px 0 0 27px;
    font-size:14pt;
    color:#037c8f;
    cursor:pointer}
.legacy #ponMainWindowWrapper h1{color:#666!important;
    font-size:1.4em!important}
.legacy .newPONClose{float:right;
    padding:0 15px 15px 15px;
    margin:11px 3px -50px 0;
    font-size:16pt;
    color:#037c8f;
    cursor:pointer;
    position:relative;
    outline:0;
    border:none;
    background:0 0}
.legacy .newPONClose:hover,.legacy .newPONClose:active,.legacy .newPONClose:focus{color:#23527c;
    text-decoration:none}
.legacy #ponContent{max-width:575px;
    padding:30px 0 30px 0}
.legacy #ponDragHandle{max-width:575px;
    width:100%;
    min-height:90px;
    height:auto;
    margin-bottom:-90px;
    position:relative;
    z-index:9999}
.legacy #ponModalContent{max-width:575px;
    border:0;
    border-radius:0}
.legacy div#ponModalContent div.modal-body{padding-top:0;
    padding-bottom:0}
.legacy .ponIframe{display:block;
    height:465px;
    max-width:575px;
    width:100%;
    border:0}
.legacy #ponBottomController{max-height:93px;
    margin-top:0;
    padding:25px 20px 25px 35px;
    text-align:left;
    background:#F0F0F0;
    color:#333;
    font-family:"Avenir roman",Helvetica,Arial,san-serif;
    font-size:11pt}
.legacy .ponHelpfulResponseNo{line-height:18px;
    padding-top:19px!important}
.legacy .ponHelpfulVote{height:24px;
    width:50px;
    padding:2px 12px 0 12px;
    color:#FFF;
    background-color:#A1A1A1;
    margin-top:-2px}
.legacy .ponHelpfulVote:last-child{margin-left:0!important}
.legacy #ponBack img{height:15px;
    margin-top:-3px}
.legacy #ponPrintLink{font-family:Avenir,Helvetica,Arial,sans-serif}
.legacy #printLink{-webkit-font-smoothing:antialiased;
    font-style:normal;
    font-size:10px}
.legacy #printLink .ponButtonText{font-size:12px}
.legacy #ponMainWindow{float:left;
    width:100%;
    height:370px;
    background-color:#FEFEFE;
    overflow:auto;
    padding:2%;
    padding-left:29px;
    padding-right:30px;
    font-size:14px;
    position:relative}
.legacy #ponMainWindow .ponHyperButton{border:none;
    background:0 0;
    color:#05A4B5;
    display:inline;
    clear:none;
    white-space:normal;
    font-size:16px;
    font-weight:500;
    margin:0;
    padding:0;
    text-align:left;
    width:auto;
    height:auto}
.legacy #ponMainWindow .ponHyperButton:hover{color:#428bca;
    text-decoration:underline}
.legacy #ponMainWindow *{font-size:14px}
.legacy #ponMainWindow p{white-space:pre-wrap}
.legacy #ponMoreWindow{display:none;
    height:370px;
    border-radius:5px;
    border:solid 2px #E5E5E5;
    overflow:hidden;
    background:#FFF;
    padding:2%}
.legacy #ponMore{width:140px;
    padding:0;
    padding-top:7px;
    padding-bottom:7px;
    font-size:14px}
.legacy #ponMoreWindow .pon-small-browser{margin-top:20px}
.legacy .ponButtonText{font-family:Avenir,Helevetica,Arial,sans-serif;
    font-size:12px;
    margin-left:10px}
.legacy .ponButtonText:hover{text-decoration:none}
.legacy #ponMoreWindow #nav-section>div{width:100%;
    font-size:1.2em;
    font-weight:700;
    line-height:1.5em;
    margin-top:-.5em}
.legacy #toggle-ask-section{display:none}
.legacy #ask-label{display:none}
.legacy #ask-inner .actions-right{display:none;
    margin-top:.75em;
    width:100%;
    text-align:right;
    clear:both}
.legacy #ask-inner .actions-right button{font-size:.9em;
    height:1.5em;
    width:auto;
    padding-left:.5em;
    padding-right:.5em}
.legacy #ask-inner textarea{line-height:1.5em;
    padding:.2em;
    font-size:.9em}
.legacy #ask-help-text{display:none;
    font-size:.7em;
    margin-top:1em;
    width:100%}
.legacy #ask{width:100%;
    height:2em;
    border-radius:5px;
    margin-top:.5em;
    margin-left:1px;
    overflow:hidden}
.legacy #ponMoreWindow hr{display:none}
.legacy #ponMoreWindow h4{font-size:.85em;
    font-weight:700;
    margin-top:1em}
.legacy #ponMoreWindow .widget-avatar{display:none}
.legacy #ponMoreWindow .widget-question .details{display:none}
.legacy #ponMoreWindow .widget-question .metadata{display:none}
.legacy #questionSection *{line-height:0;
    margin:0;
    padding:0;
    font-size:.9em}
.legacy #ponMoreWindow .widget-section :nth-of-type(4) *{line-height:0;
    padding:0;
    margin:0;
    font-size:.9em}
.legacy #ponMoreWindow .widget-section ul{margin:0;
    padding:0}
.legacy #ponMoreWindow .widget-section ul li{list-style:none;
    margin:0;
    padding:0}
.legacy #ponMoreWindow .widget-section ul li .widget-question .subject a{font-size:.9em;
    line-height:1.2em;
    display:block}
.legacy #ponMoreWindow .widget-section ul li .widget-question .subject{line-height:1em;
    margin-bottom:.25em;
    padding-bottom:.25em;
    border-bottom:solid 1px #F0F0F0}
.legacy #ponMoreWindow i{margin-right:.3em}
.legacy #ponMoreWindow .view-all{font-size:1em;
    margin-top:1em}
.legacy #ponMoreWindow .view-all i{margin-left:.3em}
.legacy .pon100Fix{width:100%}
.legacy .kHELP_HTML{font-size:12px}
.legacy .kHELP_HTML *{font-size:12px}
@media print{.legacy #modalBkg,.legacy #screenTransitionBkg,.legacy #toolsCenter,.legacy #errorTipLayer,.legacy #validationAlert,.legacy #rwdHelper,.legacy #modalLayer,.legacy #messageLayer,.legacy #mojoTransitioner,.legacy #modalBgContainer,.legacy #formsMode,.legacy #debug_console_wrapper,.legacy #rex-summary,.legacy #ponBodyController,.legacy #ponBottomController,.legacy #ponMoreWindowWrapper,.legacy #ponMoreWindow,.legacy #ponClose{display:none}
.legacy #ponContent,.legacy #ponContent *{border:none;
    width:100%}
.legacy #ponHeadline{display:block;
    width:100%}
.legacy #ponMainWindow,.legacy #ponMainWindow *{display:block}
.legacy #ponMainWindow{width:90%;
    height:auto;
    border:none;
    float:none;
    font-size:10px;
    overflow:visible!important}
}
@media (min-width:769px) and (max-width:991px){.legacy #ponMoreWindowWrapper{margin-top:10px}
}
@media (max-width:360px){.legacy #ponMore{width:90px;
    font-size:10px}
.legacy #printLink{width:90px;
    font-size:10px}
}
@media (max-width:500px){.legacy #ponMore{width:120px;
    font-size:10px}
.legacy #printLink{width:120px;
    font-size:11px;
    overflow:hidden}
}
@media (max-width:768px){.legacy #ponMoreWindow{margin-top:20px;
    margin-left:15px;
    margin-right:15px;
    width:98%;
    height:auto}
.legacy #ponMainWindow{height:auto;
    border-color:#DEDEDE;
    margin-bottom:20px}
.legacy #ponMoreWindow #nav-section>div{margin-top:.5em}
}
@media (max-width:601px){.legacy #ponMoreWindow{width:95%}
}
@media (max-width:481px){.legacy #ponMoreWindow{width:93%}
}
@media (max-width:321px){.legacy #ponMoreWindow{width:90%}
}
@media (max-width:768px) and (min-width:601px){.legacy #ponMoreWindowWrapper{padding-right:15px}
}

      #UniversalUpgrade form input[type=radio]{display:inline-block;
    width:25px}
#UniversalUpgrade iframe.externalContent{height:720px}
#UniversalUpgrade a.noILink{margin-bottom:25px}
#ConfirmUpgrade iframe.externalContent,#WelcomeToSku iframe.externalContent{height:300px}
.answerDiv .processing{margin:0 5px}
.answerDiv .btn{margin:5px 0}
#ttoInterviewContainer .upgradeSpinner{text-align:center}
@media all and (max-width:1199px){.answerDivThreeButtons .ansL{width:100%;
    text-align:center!important}
.answerDivThreeButtons .ansL button{float:none!important}
.answerDivThreeButtons .ansR{-webkit-transform:scaleY(-1);
    -ms-transform:scaleY(-1);
    transform:scaleY(-1)}
.answerDivThreeButtons .ansR button{width:100%!important;
    -webkit-transform:scaleY(-1);
    -ms-transform:scaleY(-1);
    transform:scaleY(-1)}
}
#PostPIOffer .swapUpgradeButtons,#SkuOffer .swapUpgradeButtons,#AreYouSure .swapUpgradeButtons{float:right}
@media all and (max-width:1199px){#PostPIOffer .swapUpgradeButtons,#SkuOffer .swapUpgradeButtons,#AreYouSure .swapUpgradeButtons{width:100%;
    -webkit-transform:scaleY(-1);
    -ms-transform:scaleY(-1);
    transform:scaleY(-1)}
}
#PostPIOffer .swapUpgradeButtons button,#SkuOffer .swapUpgradeButtons button,#AreYouSure .swapUpgradeButtons button{-webkit-transform:scaleY(1)!important;
    -ms-transform:scaleY(1)!important;
    transform:scaleY(1)!important}
@media all and (max-width:767px){#postPIDeluxeOfferUpgradeBtn{display:none}
}
#PostPIPaidOfferMX2C .ansR button{min-width:210px}
@media all and (min-width:768px){#PostPIPaidOfferMX2C .ansR button{min-height:54px}
}
#PostPIPaidOfferMX2C .multiline>span{display:block}
#PostPIPaidOfferMX2C .subtitle{font-size:12px}
#PostPIPaidOfferMX2C .largeFont{font-size:18px}
@media all and (min-width:768px){#PostPIPaidOfferMX2C .largeFont{line-height:14px}
}

      @media all and (max-width:480px){.ttoWelcomeDiv .primary-action{width:100%}
}
.ttoWelcomeDiv .subItem{color:#8d9096;
    padding:8px 0 8px 45px}
.ttoWelcomeDiv .life{background:url();
    background-repeat:no-repeat}
.ttoWelcomeDiv .tax{background:url();
    background-repeat:no-repeat}
.ttoWelcomeDiv .return{background:url();
    background-repeat:no-repeat}
.ttoWelcomeDiv .intro_hero{text-align:center}
.ttoWelcomeDiv .intro_hero img{vertical-align:middle}
.ttoWelcomeDiv .welcomeSection{border-bottom:1px solid #ccc;
    padding-bottom:10px;
    margin-bottom:20px}
.ttoWelcomeDiv .welcomeBullets{padding-left:10px}
.ttoWelcomeDiv .welcomeBullets li{margin-left:10px;
    color:#09F}
@media all and (max-width:767px){.ttoWelcomeDiv .welcomeBullets{padding-left:0;
    list-style-type:none}
.ttoWelcomeDiv .welcomeBullets li{margin-left:0}
}
.ttoWelcomeDiv .lnk{display:inline-block}
.ttoWelcomeDiv .footerLink{text-decoration:none}
.ttoWelcomeDiv #welcomeFFASection{margin:auto}
.ttoWelcomeDiv #welcomeFFASection .sub-title{margin-top:30px;
    margin-bottom:35px}
.ttoWelcomeDiv #welcomeFFASection .sub-title p{margin:0}
.ttoWelcomeDiv #welcomeFFASection .content{margin-bottom:70px}
.ttoWelcomeDiv #welcomeFFASection .ffa-qualifications{margin-top:0;
    margin-bottom:30px}
.ttoWelcomeDiv #welcomeFFASection .ffa-qualifications p{margin:0}
.ttoWelcomeDiv #welcomeFFASection .ffa-qualifications ul{margin-top:35px;
    padding-left:55px;
    margin-bottom:30px}
.ttoWelcomeDiv #welcomeFFASection .ffa-qualifications li{color:#6b6c72;
    list-style-type:none}
.ttoWelcomeDiv #welcomeFFASection .ffa-qualifications li:before{content:'\2022';
    padding-right:.5em}
.ttoWelcomeDiv #welcomeFFASection .divider{font-size:0;
    border-top:solid 1px #ccc;
    width:45%;
    margin-bottom:30px}
.ttoWelcomeDiv #welcomeFFASection .savings-container .text-container .header{font-weight:700;
    font-size:17px;
    margin-bottom:10px}
.ttoWelcomeDiv #welcomeFFASection .savings-container .text-container p{margin-top:0}
.ttoWelcomeDiv #welcomeFFASection .savings-container.piggy{padding-top:20px;
    padding-bottom:20px;
    margin-left:15px;
    background-color:#ebf9ff}
.ttoWelcomeDiv #welcomeFFASection .savings-container.piggy .img-container .financial-savings{margin-right:20px;
    width:50px}
.ttoWelcomeDiv #welcomeFFASection .savings-container.piggy .text-container{padding-right:0;
    margin-left:10px}
.ttoWelcomeDiv #welcomeFFASection .savings-container.piggy .text-container .header{font-weight:500}
.ttoWelcomeDiv #welcomeFFASection .savings-container.piggy .text-container .verbiage{line-height:1.25;
    margin-bottom:20px}
.ttoWelcomeDiv #welcomeFFASection .savings-container.piggy .text-container p{margin:0;
    margin-bottom:5px}
.ttoWelcomeDiv #welcomeFFASection .savings-container.piggy .text-container label{font-weight:100}
.ttoWelcomeDiv #transferInfo ul{list-style:none;
    margin:2% 0;
    padding:0 3%}
.ttoWelcomeDiv #transferInfo li{padding:1%}
.ttoWelcomeDiv .militaryNames{font-size:.7em;
    color:#EEE;
    background-color:#514C52;
    padding:5px}
.ttoWelcomeDiv .militaryNames div{display:inline-block;
    width:24%;
    text-align:center;
    vertical-align:top}
.ttoWelcomeDiv .militaryNames .role{font-weight:700;
    display:block}
.ttoWelcomeDiv #welcomeUnavailableForms{width:100%;
    height:50px}
.ttoWelcomeDiv .unavailableFormItem{width:32%;
    display:block;
    float:left;
    padding-left:5px;
    padding-right:5px}
.ttoWelcomeDiv .welcome-qbse{display:none;
    text-align:center;
    margin:20px 0 50px 0;
    border-bottom:1px solid #ccc}
.ttoWelcomeDiv .welcome-qbse a{color:#99dbf9}
.ttoWelcomeDiv .welcome-qbse .content{width:95%;
    margin:0 2.5% 35px 2.5%;
    position:relative;
    color:#666}
@media (max-width:992px){.ttoWelcomeDiv .welcome-qbse .content{width:75%;
    margin:0 12.5% 35px 12.5%}
}
@media (max-width:767px){.ttoWelcomeDiv .welcome-qbse .content{width:100%;
    margin:0 0 35px 0;
    text-align:center}
}
.ttoWelcomeDiv .welcome-qbse .content .qb-logo{position:absolute;
    width:115px;
    top:-45px;
    right:-20px}
@media (max-width:992px){.ttoWelcomeDiv .welcome-qbse .content .qb-logo{right:-75px}
}
@media (max-width:767px){.ttoWelcomeDiv .welcome-qbse .content .qb-logo{display:none}
}
.ttoWelcomeDiv .welcome-qbse .content h1{font-size:30px;
    color:#333;
    text-align:center;
    margin-bottom:20px;
    margin-top:0;
    line-height:40px;
    font-weight:100;
    -webkit-font-smoothing:antialiased}
.ttoWelcomeDiv .welcome-qbse .content .main-img{width:125px;
    margin-bottom:30px}
.ttoWelcomeDiv .welcome-qbse .content .msg-failed{display:none}
.ttoWelcomeDiv .welcome-qbse .content p{margin-bottom:20px;
    font-size:16px;
    line-height:22px}
@media (max-width:992px){.ttoWelcomeDiv .welcome-qbse .content p{width:80%;
    margin:0 10% 30px 10%}
}
@media (max-width:767px){.ttoWelcomeDiv .welcome-qbse .content p{width:100%;
    margin:0 0 30px 0}
}
.ttoWelcomeDiv .welcome-qbse .content p.qb-intro{width:82%;
    margin:0 9% 20px 9%}
.ttoWelcomeDiv .welcome-qbse .content ul{width:50%;
    text-align:left;
    display:inline-block;
    margin:0 20% 30px 24%}
.ttoWelcomeDiv .welcome-qbse .content ul.qb-intro{margin:0 20% 20px 27%}
@media (max-width:1200px){.ttoWelcomeDiv .welcome-qbse .content ul.qb-intro{margin:0 20% 30px 24%}
}
@media (max-width:992px){.ttoWelcomeDiv .welcome-qbse .content ul.qb-intro{margin:0 0 30px 0}
}
@media (max-width:1200px){.ttoWelcomeDiv .welcome-qbse .content ul{width:75%;
    margin:0 7% 30px 18%}
}
@media (max-width:992px){.ttoWelcomeDiv .welcome-qbse .content ul{width:100%;
    margin:0 0 30px 0;
    padding-left:50px}
}
@media (max-width:767px){.ttoWelcomeDiv .welcome-qbse .content ul{width:auto;
    margin:0 auto 30px auto;
    padding-left:20px;
    display:inline-block}
}
.ttoWelcomeDiv .welcome-qbse .content ul li{margin-bottom:2px;
    padding:0;
    font-size:16px}
.ttoWelcomeDiv .welcome-qbse .content ul li:last-child{margin-bottom:0}
.ttoWelcomeDiv .welcome-qbse .content button{border:0;
    display:inline-block;
    background-color:#037c8f;
    color:#fff;
    padding:10px 16px;
    font-size:18px;
    line-height:1.33;
    border-radius:4px;
    box-shadow:0 1px 1px 0 rgba(0,0,0,.5);
    margin:0 5px 15px 5px}
@media (max-width:767px){.ttoWelcomeDiv .welcome-qbse .content button{width:100%;
    max-width:100%}
}
.ttoWelcomeDiv .welcome-qbse .content button.btn-outline{border:1px solid #037c8f!important;
    background:#fff!important;
    color:#037c8f!important}
.ttoWelcomeDiv.view-qbse.qbse-failed .welcome-qbse .content .main-img{left:30px}
.ttoWelcomeDiv.view-qbse.qbse-failed .welcome-qbse .content .msg-failed{display:block}
.ttoWelcomeDiv.view-qbse.qbse-failed .welcome-qbse .content .msg-success{display:none}
.ttoWelcomeDiv.view-qbse .welcome-qbse{display:block}
.ttoWelcomeDiv.view-qbse #welcomeSection,.ttoWelcomeDiv.view-qbse .col-sm-8,.ttoWelcomeDiv.view-qbse .intro_hero,.ttoWelcomeDiv.view-qbse #welcomeFFASection{display:none}
.custom-error-container{text-align:center}
.custom-error-container .error-image{margin-bottom:25px}
.custom-error-container .start-dialog{font-size:35px;
    font-weight:500;
    color:#000;
    line-height:1.2;
    margin-bottom:25px}
.custom-error-container .mid-dialog{background-color:#F1F1F1;
    width:80%;
    font-size:16px;
    padding:25px 30px;
    margin:0 auto 25px}
.custom-error-container .mid-dialog .line1{margin-bottom:15px}
.custom-error-container .end-dialog{margin-bottom:100px;
    font-size:16px;
    font-weight:100}
.ttoHelpPop{padding:0 30px 0 10px}
.ttoHelpPop li{margin-bottom:20px}
.ttoEfileStatus{font-weight:700;
    margin-right:10px}
.ttoEfileStatus.pending{color:#157DB7}
.ttoEfileStatus.accepted{color:green}
.ttoEfileStatus.rejected{color:red}
.ttoEfileStatus.unavailable{color:red}
@keyframes fadeIn{from{opacity:0;
    bottom:-10px}
to{bottom:0;
    opacity:1}
}
@-webkit-keyframes fadeIn{from{opacity:0;
    bottom:-10px}
to{bottom:0;
    opacity:1}
}
@-moz-keyframes fadeIn{from{opacity:0;
    bottom:-10px}
to{bottom:0;
    opacity:1}
}
@-ms-keyframes fadeIn{from{opacity:0;
    bottom:-10px}
to{bottom:0;
    opacity:1}
}
.ttoAlignCenter{text-align:center}
.ttoVerticalTop{vertical-align:top}
.ttoInlineBlock{display:inline-block!important}
.img_slideUp_fadein{position:relative;
    bottom:0;
    opacity:0;
    animation:fadeIn 1.5s forwards;
    animation-delay:1.5s;
    -o-animation:fadeIn 1.5s forwards;
    -o-animation-delay:1.5s;
    -moz-animation:fadeIn 1.5s forwards;
    -moz-animation-delay:1.5s;
    -webkit-animation:fadeIn 1.5s forwards;
    -webkit-animation-delay:1.5s}
#ttoReturningUserDiv .main-logo{width:115px}
#ttoReturnUserDiv .h1_adjustment{text-align:center;
    color:#333;
    font-weight:lighter;
    font-size:30px;
    margin:13px 0 15px}
#ttoReturnUserDiv .text_adjustment{color:#888;
    margin:6px auto 30px}
#ttoReturnUserDiv .img_adjustment img{margin-bottom:30px}
#ttoReturnUserDiv #continueButton{margin:auto auto 41px}
#ttoReturnUserDiv .welcomeSection p{margin:0}
@media (max-width:480px){#ttoReturnUserDiv .main-logo{width:115px;
    padding-bottom:20px}
#ttoReturnUserDiv #continueButton{margin:10px auto}
}
@media (max-width:768px){#ttoReturnUserDiv .main-logo{width:115px;
    padding-bottom:20px}
}

      .ttoImportWidgetDiv #PriorYearImportDiv .externalWidget{width:100%}

      #VersionInfo .processing{margin:45%}
